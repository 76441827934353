import { PaymentMethod } from '@stripe/stripe-js'
import { CarouselRef } from 'antd/lib/carousel'
import { AuthStateContext } from 'context'
import { NotyfContext } from 'context/NotyfContext'
import { buildSubscriptionsFromPrices } from 'features/Subscription/utils'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { PropsWithChildren, SetStateAction, useCallback, useContext, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { SubscriptionsAPI, PromoCodesAPI } from 'services/api'
import { CreateCustomerOptions, CreateSubscriptionOptions } from 'services/api/types'
import { FirebaseAuth } from 'services/firebase'
import { Validations } from 'shared/functions'
import { useCountry } from 'hooks/useCountry'

import ReactPixel from 'react-facebook-pixel'
import { firebase } from 'services/firebase'
// import * as Stripe from '../../shared/functions/stripe'
import { usePromoCodes } from 'hooks/usePromoCodes'
import { isDefaultPrice } from '../../shared/functions/stripe'
import { Price, SubscriptionOptionType } from 'types'

type SubscriptionParams = {
  type?: 'update' | string
}

type CreateAccount = {
  email: string
  password: string
  fullName: string
}

const SignUp = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { data: country, isLoading: countryLoading } = useCountry()
  const { type } = useParams<SubscriptionParams>()
  const signUpFormCarouselRef = React.useRef<CarouselRef>(null)
  const [isMobile, setIsMobile] = React.useState(false)
  const [selectedPlan, setSelectedPlan] = React.useState<string>('')
  const [selectedPrice, setSelectedPrice] = React.useState<Price | undefined>()
  const [userDetails, setUserDetails] = React.useState<CreateAccount>({} as CreateAccount)
  const [userDetailsError, setUserDetailsError] = React.useState<any>()
  const { state: authState } = useContext(AuthStateContext)
  const { setValue } = useLocalStorage('createAccount', '')
  const notyf = useContext(NotyfContext)
  const [creatingAccount, setCreatingAccount] = React.useState(false)
  const isStaging = window.location.href.includes('staging') || window.location.href.includes('localhost')
  const [promoCode, setPromoCode] = React.useState<string>('')
  const [showMonthlyDiscount, setShowMonthlyDiscount] = React.useState(false)
  const [showQuarterlyDiscount, setShowQuarterlyDiscount] = React.useState(false)
  const [showAnnualDiscount, setShowAnnualDiscount] = React.useState(false)

  const onSelectPlan = useCallback((plan: string, price: Price) => {
    console.log('Plan Selected: ', plan)
    setSelectedPlan(plan)
    setSelectedPrice(price)
    setPromoCodeOnPlanSelection(plan)
  }, [])

  const { data: prices, isLoading: pricesLoading } = useQuery('prices', SubscriptionsAPI.getSubscriptionPrices, {
    staleTime: Infinity, // prices wont change frequently
  })

  console.log('Raw prices from API:', prices)
  console.log('Country data:', country)
  // const displayCurrency = country?.countryCode === 'CA' ? 'CAD' : 'USD'
  // console.log('Selected currency:', displayCurrency)
  const countryCode = useMemo(() => {
    if (country) {
      return country.countryCode
    }
    return null
  }, [country])

  const displayedCurrency = useMemo(() => {
    if (countryCode === 'CA') {
      return 'CAD'
    }
    return 'USD'
  }, [country])

  const subscriptionOptions = React.useMemo(() => buildSubscriptionsFromPrices(prices), [prices, country])
  console.log('Built subscription options:', subscriptionOptions)
  const { data: promoCodes, isLoading: promoCodesLoading } = usePromoCodes()

  const setNormalizedPromoCode = (code: SetStateAction<string>) => {
    let codeString = code.toString()
    codeString = codeString.trim()
    setPromoCode(codeString.toUpperCase())
  }

  const filterOptionsByCurrency = (options: SubscriptionOptionType[], currency: string) => {
    if (!options || options.length === 0) {
      return []
    }
    if (!currency) {
      return options
    }
    console.log(`filterOptionsByCurrency:option.currency: ${options[0]?.currency} Desiredcurrency: ${currency}`)
    return options.filter((option) => option?.currency?.toLowerCase() === currency.toLowerCase())
  }

  const options = useMemo(() => {
    if (!displayedCurrency) {
      return null
    }
    if (!subscriptionOptions) {
      return null
    }

    const filteredOptions = filterOptionsByCurrency(subscriptionOptions, displayedCurrency)
    console.log(`filteredOptions: ${JSON.stringify(filteredOptions)}`)
    const mapped = filteredOptions.map((option) => {
      console.log(`filterOptionsByCurrency:option.currency: ${JSON.stringify(option)} Desiredcurrency: ${displayedCurrency}`)
      // Start with the base subscription data
      const baseOption = {
        ...option,
        hasPromo: false,
        promoPrice: undefined,
        promoText: undefined,
      }

      // Add any special promo details if applicable
      if (option.type === 'yearly' && isDefaultPrice(option.price)) {
        return {
          ...baseOption,
          hasPromo: showAnnualDiscount,
          promoPrice: showAnnualDiscount ? 99.99 : undefined,
          promoText: showAnnualDiscount ? '50% off for the first year!' : undefined,
        }
      }
      if (option.type === 'quarterly' && isDefaultPrice(option.price)) {
        return {
          ...baseOption,
          hasPromo: showQuarterlyDiscount,
          promoPrice: showQuarterlyDiscount ? 29.99 : undefined,
          promoText: showQuarterlyDiscount ? '45% off for the first quarter!' : undefined,
        }
      }
      if (option.type === 'monthly' && isDefaultPrice(option.price)) {
        return {
          ...baseOption,
          hasPromo: showMonthlyDiscount,
          promoPrice: showMonthlyDiscount ? 12.49 : undefined,
          promoText: showMonthlyDiscount ? '50% off for 6 months!' : undefined,
        }
      }

      return baseOption
    })
    console.log(`mapped Options: ${JSON.stringify(mapped)}`)
    return mapped
  }, [subscriptionOptions, displayedCurrency, showMonthlyDiscount, showQuarterlyDiscount, showAnnualDiscount])

  const availablePlans = useMemo(() => {
    if (!options) return null

    const monthlyPlan = options.find((o: SubscriptionOptionType) => {
      return o.type === 'monthly' && isDefaultPrice(o.price, displayedCurrency)
    })
    const annualPlan = options.find((o: SubscriptionOptionType) => {
      return o.type === 'yearly' && isDefaultPrice(o.price, displayedCurrency)
    })
    const quarterlyPlan = options.find((o: SubscriptionOptionType) => {
      return o.type === 'quarterly' && isDefaultPrice(o.price, displayedCurrency)
    })

    const activeMonthly = monthlyPlan && monthlyPlan.type && selectedPlan === monthlyPlan.type
    const activeQuarterly = quarterlyPlan && quarterlyPlan.type && selectedPlan === quarterlyPlan.type
    const activeYearly = annualPlan && annualPlan.type && selectedPlan === annualPlan.type

    return {
      activePlans: {
        monthlyPlan,
        quarterlyPlan,
        annualPlan,
      },
      activeMonthly,
      activeQuarterly,
      activeYearly,
    }
  }, [options, selectedPlan, displayedCurrency])

  const onPressLogin = useCallback(() => {
    history.push('/login')
  }, [history])

  const setPromoCodeOnPlanSelection = (plan: string) => {
    // Disabled as there are no default codes at this time.
    return

    // const promoCodePlan = new Map()
    // // promoCodePlan.set('monthly', 'BF50')
    // // promoCodePlan.set('quarterly', 'BF45')
    // // promoCodePlan.set('monthly', 'NYL50')
    // // promoCodePlan.set('quarterly', 'NYL45')

    // const promoCode = promoCodePlan.get(plan)
    // // console.log(`found promo code: ${promoCode}`)
    // if (promoCode) {
    //   console.log(`setPromoCodeOnPlanSelection setting promo: ${promoCode}`)
    //   setPromoCode(promoCode)
    // } else {
    //   // clear the promo code
    //   setPromoCode('')
    // }
  }

  const goToNextAccessForm = useCallback(() => signUpFormCarouselRef.current?.next(), [])
  const goToPrevAccessForm = useCallback(() => signUpFormCarouselRef.current?.prev(), [])

  const onSubmitPlan = useCallback(() => {
    goToNextAccessForm()
  }, [])

  const onClickCreateAccount = async ({ email, password, fullName }: any) => {
    console.log('onClickCreateAccount', email, password, fullName, promoCode)
    const required = { email, password, fullName }
    const newErrors = Validations.validateForm(required)
    if (Object.keys(newErrors).length > 0) {
      console.log('newErrors', newErrors)
      setUserDetailsError(newErrors)
      return false
    }

    try {
      await FirebaseAuth.createFirebaseUserWithEmail({ email, password })
      // Update User with First and Last Name
      setValue({ fullName })
      await FirebaseAuth.updateProfile({ displayName: `${fullName}` })

      return true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('error', error.message)
      notyf.error(error.message)
      if (error.message.includes('The email address is already in use by another account')) {
        history.push('/subscription')
      }
      return false
    }
  }

  const validatePassword = (password = '') => {
    const isValid = Validations.validPassword(password, false)

    return isValid === ''
  }

  const onSubmitUserDetails = useCallback(async (userDetails: { email: string; terms: boolean; password: string; firstName: string; lastName: string }) => {
    setUserDetails({
      email: userDetails.email,
      password: userDetails.password,
      fullName: userDetails.firstName + ' ' + userDetails.lastName,
    })

    if (userDetails.terms && validatePassword(userDetails.password)) {
      goToNextAccessForm()
    }
  }, [])

  const onChangeCheckbox = (e: { target: { checked: any } }) => {
    console.log(`checked = ${e.target.checked}`)
  }

  const { mutateAsync: createCustomer } = useMutation((createOptions: CreateCustomerOptions) => {
    console.log('*** createOptions', createOptions)

    return SubscriptionsAPI.createStripeCustomer(createOptions)
  })

  const { mutate: createSubscription, isLoading: creatingSubscription } = useMutation(
    (createOptions: CreateSubscriptionOptions) => {
      console.log('*** createOptions', createOptions)
      createOptions.promoCode = promoCode
      return SubscriptionsAPI.createCustomerSubscription(createOptions)
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('user')
        if (type && type === 'update') {
          history.goBack()
        } else {
          // Auth Context navigates to get-started based on auth state
          const { pathname } = location
          ReactPixel.trackCustom('WebSignupConversion')
          firebase.analytics().logEvent('web_sign_up', {
            content_type: selectedOption?.type,
          })
          if (pathname.includes('app-redirect')) {
            history.replace('/welcome-email')
          } else {
            history.replace('/get-started')
          }
        }
      },
      onError: (err: Error) => {
        notyf.error(err.message)
      },
    },
  )

  const selectedOption = options?.find((option: { type: string }) => {
    return option.type === selectedPlan
  })

  const createCustomerSubscription = async (paymentMethod: PaymentMethod, name: string, promoCode?: string, country?: string) => {
    const createCustomerResponse = await createCustomer({
      name: name,
      email: userDetails.email,

      metadata: {
        ...(authState.user && {
          userId: authState.user.id || '',
          firstName: authState.user.firstName || '',
          lastName: authState.user.lastName || '',
        }),
      },
    })

    const customerId = createCustomerResponse.customer_id

    if (selectedOption) {
      createSubscription({
        customerId: customerId,
        paymentMethodId: paymentMethod.id,
        priceId: selectedOption.id || '',
        metadata: {},
        promoCode: promoCode,
      })
    }
  }

  const onPaymentSuccess = async (paymentMethod: PaymentMethod, name: string, promoCode?: string) => {
    try {
      if (authState.user?.id) {
        createCustomerSubscription(paymentMethod, name, promoCode, countryCode ?? 'US')
      } else {
        const accountCreated = await onClickCreateAccount(userDetails)

        if (accountCreated) {
          await createCustomerSubscription(paymentMethod, name, promoCode, countryCode ?? 'US')
        }
      }
    } catch (e: unknown) {
      setCreatingAccount(false)
      console.log(e)
    }
  }

  return {
    creatingSubscription,
    options,
    selectedOption,
    onPressLogin,
    setIsMobile,
    isMobile,
    subscriptionOptions,
    selectedPlan,
    selectedPrice,
    onSelectPlan,
    signUpFormCarouselRef,
    goToNextAccessForm,
    goToPrevAccessForm,
    onSubmitPlan,
    onSubmitUserDetails,
    prices,
    pricesLoading,
    onChangeCheckbox,
    isStaging,
    userDetails,
    onPaymentSuccess,
    creatingAccount,
    setCreatingAccount,
    promoCode,
    setPromoCode: setNormalizedPromoCode,
    displayedCurrency,
    availablePlans,
  }
}

type SignUpContextType = ReturnType<typeof SignUp>

const SignUpContext = React.createContext<SignUpContextType>({} as SignUpContextType)
SignUpContext.displayName = 'SignUpContext'

export const SignUpContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const value = SignUp()
  return <SignUpContext.Provider value={value}>{children}</SignUpContext.Provider>
}

export const useSignUpContext = () => React.useContext(SignUpContext)
