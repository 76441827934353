import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as ReservationsServices from 'services/api/reservations'
import { Reservation } from 'types'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'

import { NotyfContext } from 'context/NotyfContext'

export const useReservations = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const notyf = useContext(NotyfContext)

  // -----------------------------
  // 1) Fetch Reservations
  // -----------------------------
  const {
    data: reservation,
    isLoading: reservationLoading,
    error: reservationError,
  } = useQuery<Reservation>('reservations', ReservationsServices.getAllReservations, {
    staleTime: Infinity,
  })

  const userHasReservation = useMemo((): boolean => {
    if (reservation) {
      return true
    } else {
      return false
    }
  }, [reservation])

  const createReservationMutation = useMutation(
    (data: { userId: string; challengeId: string; startDate: string; preferences: object; redirect?: boolean }) => ReservationsServices.createReservation(data),
    {
      onSuccess: (data, variables) => {
        // Refresh reservations so we see the new one
        queryClient.invalidateQueries('reservations')

        if (variables?.redirect) {
          history.push('/reservations')
        }
      },
    },
  )

  const createReservation = async (data: { userId: string; challengeId: string; startDate: string; preferences: object; redirect?: boolean }) => {
    return createReservationMutation.mutateAsync(data)
  }

  // -----------------------------
  // 3) Cancel Reservation
  // -----------------------------
  const cancelReservationMutation = useMutation((reservationId: string) => ReservationsServices.cancelReservation(reservationId), {
    onSuccess: () => {
      queryClient.setQueryData('reservations', null)
      queryClient.invalidateQueries('reservations')
    },
  })

  const cancelReservation = async (reservationId: string) => {
    return cancelReservationMutation.mutateAsync(reservationId)
  }

  // -----------------------------
  // 4) Update Reservation
  // -----------------------------
  const updateReservationMutation = useMutation((params: { reservationId: string; data: any }) => ReservationsServices.updateReservation(params.reservationId, params.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('reservations')
    },
  })

  const updateReservation = async (reservationId: string, data: any) => {
    return updateReservationMutation.mutateAsync({ reservationId, data })
  }

  // -----------------------------
  // 5) Act on Reservation
  // -----------------------------
  const actionReservationMutation = useMutation(
    (params: { reservationId: string; isDownloadWeek: boolean }) => ReservationsServices.actionReservation(params.reservationId, params.isDownloadWeek),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('reservations')
        window.location.reload()
      },
    },
  )

  const actionReservation = async (reservationId: string, isDownloadWeek: boolean) => {
    return actionReservationMutation.mutateAsync({ reservationId, isDownloadWeek })
  }

  return {
    // Data
    reservation,
    reservationLoading,
    reservationError,
    userHasReservation,

    // Create
    createReservation,
    creatingReservation: createReservationMutation.isLoading,

    // Cancel
    cancelReservation,
    cancelingReservation: cancelReservationMutation.isLoading,

    // Update
    updateReservation,
    updatingReservation: updateReservationMutation.isLoading,

    // action
    actionReservation,
    actingReservation: actionReservationMutation.isLoading,
  }
}
