import React from 'react'
import { UserSubscription, StripeCoupon } from 'types'
import { dayjs } from 'shared/functions'

interface DiscountProps {
  subscription: UserSubscription
}

const formatDiscountAmount = (coupon: StripeCoupon): string => {
  if (coupon.percent_off) {
    return `${coupon.percent_off}% Off`
  }

  if (coupon.amount_off) {
    return `$${(coupon.amount_off / 100).toFixed(2)} Off`
  }

  return 'Discount applied'
}

const getDurationText = (coupon: StripeCoupon, startDate: string): string => {
  if (coupon.duration === 'once') {
    return 'Coupon applied'
  }

  if (coupon.duration === 'repeating' && coupon.duration_in_months) {
    const endDate = dayjs(startDate).add(coupon.duration_in_months, 'month')
    return `Ends ${endDate.format('MMM D, YYYY')}`
  }

  return 'Coupon applied'
}

const getDiscountText = (subscription: UserSubscription): string | null => {
  const stripeDetails = subscription?.stripe_details
  if (!stripeDetails) return null

  const coupon = stripeDetails.coupon || stripeDetails.discount?.coupon
  if (!coupon) return null

  try {
    const discountAmount = formatDiscountAmount(coupon)
    if (!subscription.created_at) return null

    const startDate = dayjs(subscription.created_at).format('MMM D, YYYY')
    const durationText = getDurationText(coupon, subscription.created_at)

    return `${coupon.name} - ${discountAmount} \n(Started ${startDate}, ${durationText})`
  } catch (error) {
    console.error('Error formatting discount text:', error)
    return null
  }
}

export const Discount: React.FC<DiscountProps> = ({ subscription }) => {
  const discountText = getDiscountText(subscription)

  if (!discountText) {
    return null
  }

  return (
    <div
      className="w-full flex items-center bg-emerald-50 text-emerald-700 px-4 py-3 rounded-lg text-sm font-medium border border-emerald-200 leading-relaxed"
      role="status"
      aria-label="Discount information"
    >
      <span className="block w-full">
        {discountText.split('\n').map((text, i) => (
          <React.Fragment key={i}>
            {text}
            {i === 0 && <br />}
          </React.Fragment>
        ))}
      </span>
    </div>
  )
}
