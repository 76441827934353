import React from 'react'
import {
  Today,
  ForgotPassword,
  // Landing,
  AdminIndex,
  Login,
  Questionnaire,
  ResetPassword,
  OnDemand,
  ListOnDemandWorkoutClass,
  ListOnDemandWorkoutType,
  ListOnDemandWorkoutFavorite,
  Profile,
  Recipes,
  Planner,
  Squads,
  OnDemandCategory,
  WorkoutOverview,
  ViewAllWorkouts,
  ViewChallenges,
  RecipeOverview,
  ChallengeOverview,
  ChooseMeals,
  TransformationPhotos,
  Preferences,
  WorkoutComplete,
  Subscription,
  WorkoutHistory,
  Measurements,
  ResourceOverview,
  WelcomeEmailConfirmation,
  SignUp,
  AchievementOverview,
} from 'features'
import { SubscriptionWrapper } from 'features/Subscription/components/SubscriptionWrapper'
import { ReactComponent as PlannerIcon } from 'assets/icons/calendar-event.svg'
import { ReactComponent as RecipesIcon } from 'assets/icons/restaurant.svg'
import { ReactComponent as SquadsIcon } from 'assets/icons/two-messages.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg'
import { ReactComponent as ThunderIcon } from 'assets/icons/thunder.svg'
import { ReactComponent as TodayIcon } from 'assets/icons/today.svg'
import { ReactComponent as OnDemandIcon } from 'assets/icons/on-demand.svg'
import { ReactComponent as AdminIcon } from 'assets/icons/admin.svg'
import { ReactComponent as TrophyIcon } from 'assets/icons/trophy.svg'

export interface RouteType {
  path: string
  component: React.FC
  name: string
  exact: boolean
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | null
}

const subscriptionRoute: RouteType = {
  path: '/subscription',
  component: SubscriptionWrapper,
  name: 'Subscription',
  exact: true,
}

export const authRoutes: Array<RouteType> = [
  // {
  //   path: '/',
  //   component: Landing,
  //   name: 'Landing',
  //   exact: true,
  // },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    exact: true,
  },
  {
    path: '/sign-up',
    component: SignUp,
    name: 'CreateAccount',
    exact: true,
  },

  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'ForgotPassword',
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'Reset Password',
    exact: true,
  },
  {
    path: '/get-started',
    component: Questionnaire,
    name: 'Questionnaire',
    exact: true,
  },
  {
    path: '/welcome-email',
    component: WelcomeEmailConfirmation,
    name: 'WelcomeEmailConfirmation',
    exact: true,
  },
  subscriptionRoute,
]

const todayRoute: RouteType = {
  path: '/today',
  component: Today,
  name: 'Today',
  exact: true,
  icon: TodayIcon,
}

const todayQueryRoute: RouteType = {
  path: '/today/:queryDate',
  component: Today,
  name: 'Today',
  exact: true,
  icon: TodayIcon,
}

const onDemandRoute: RouteType = {
  path: '/on-demand',
  component: OnDemand,
  name: 'On Demand',
  exact: true,
  icon: OnDemandIcon,
}

const onDemandCategoryRoute: RouteType = {
  path: '/on-demand/workouts',
  component: OnDemandCategory,
  name: 'On Demand Workouts',
  exact: true,
  icon: OnDemandIcon,
}

const ListOnDemandWorkoutTypeRoute: RouteType = {
  path: '/on-demand/workout-type/:category',
  component: ListOnDemandWorkoutType,
  name: 'On Demand Workouts',
  exact: true,
  icon: OnDemandIcon,
}

const ListOnDemandWorkoutClassRoute: RouteType = {
  path: '/on-demand/workout-class/:category',
  component: ListOnDemandWorkoutClass,
  name: 'On Demand Workouts',
  exact: true,
  icon: OnDemandIcon,
}

const ListOnDemandWorkoutFavoriteRoute: RouteType = {
  path: '/on-demand/workout-favorites',
  component: ListOnDemandWorkoutFavorite,
  name: 'On Demand Workouts',
  exact: true,
  icon: OnDemandIcon,
}

const workoutOverviewRoute: RouteType = {
  path: '/workouts/:workoutId/overview',
  component: WorkoutOverview,
  name: 'Workout Overview',
  exact: true,
}
const workoutCompleteRoute: RouteType = {
  path: '/workouts/:workoutId/complete',
  component: WorkoutComplete,
  name: 'Workout Complete',
  exact: true,
}

const finisherOverviewRoute: RouteType = {
  path: '/workouts/:workoutId/overview',
  component: WorkoutOverview,
  name: 'Workout Overview',
  exact: true,
}
const plannerRoute: RouteType = {
  path: '/planner',
  component: Planner,
  name: 'Planner',
  exact: true,
  icon: PlannerIcon,
}
const viewAllWorkoutsRoute: RouteType = {
  path: '/challenge/:challengeId/workouts',
  component: ViewAllWorkouts,
  name: 'View All Challenge Workouts',
  exact: true,
}
const viewAllChallengesRoute: RouteType = {
  path: '/challenges',
  component: ViewChallenges,
  name: 'Challenges',
  exact: true,
  icon: ThunderIcon,
}
// const viewAllChallengesRoute: RouteType = {
//   path: '/challenges',
//   component: ViewChallenges,
//   name: '',
//   exact: true,
//   icon: ThunderIcon,
// };

const challengesSeriesRoute: RouteType = {
  path: '/challenges/series/:seriesId',
  component: ViewChallenges,
  name: 'Challenges Series',
  exact: true,
  icon: ThunderIcon,
}

const challengeOverviewRoute: RouteType = {
  path: '/challenges/:challengeId/overview',
  component: ChallengeOverview,
  name: 'Series Overview',
  exact: true,
}

const resourceRoute: RouteType = {
  path: '/resources/:resourceId/overview',
  component: ResourceOverview,
  name: 'Resource',
  exact: true,
}

const recipesRoute: RouteType = {
  path: '/recipes',
  component: Recipes,
  name: 'Recipes',
  exact: true,
  icon: RecipesIcon,
}
const recipeOverviewRoute: RouteType = {
  path: '/recipes/:recipeId/overview',
  component: RecipeOverview,
  name: 'Recipe Overview',
  exact: true,
}
const chooseMealsRoute: RouteType = {
  path: '/recipes/choose-meals',
  component: ChooseMeals,
  name: 'Choose Meals',
  exact: true,
}
const squadsRoute: RouteType = {
  path: '/squads',
  component: Squads,
  name: 'Squads',
  exact: true,
  icon: SquadsIcon,
}
const profileRoute: RouteType = {
  path: '/profile',
  component: Profile,
  name: 'Profile',
  exact: true,
  icon: ProfileIcon,
}
const transformationPhotosRoute: RouteType = {
  path: '/profile/transformation',
  component: TransformationPhotos,
  name: 'TransformationPhotos',
  exact: true,
}
const preferencesRoute: RouteType = {
  path: '/profile/preferences/:preferenceType',
  component: Preferences,
  name: 'Preferences',
  exact: true,
}
const subscriptionUpdateRoute: RouteType = {
  path: '/profile/subscription/:type',
  component: SubscriptionWrapper,
  name: 'Subscription',
  exact: true,
}
const workoutHistoryRoute: RouteType = {
  path: '/profile/workout-history',
  component: WorkoutHistory,
  name: 'Workout History',
  exact: true,
}
const measurementsRoute: RouteType = {
  path: '/profile/measurements',
  component: Measurements,
  name: 'User Measurements',
  exact: true,
}

const achievementRoute: RouteType = {
  path: '/achievements',
  component: AchievementOverview,
  name: 'Leanchievements',
  exact: true,
  icon: TrophyIcon,
}

const adminRoute: RouteType = {
  path: '/admin/',
  component: AdminIndex,
  name: 'Admin Dashboard',
  exact: true,
  icon: AdminIcon,
}

export const authenticatedRoutes: Array<RouteType> = [
  todayRoute,
  todayQueryRoute,
  onDemandRoute,
  plannerRoute,
  viewAllWorkoutsRoute,
  recipesRoute,
  squadsRoute,
  profileRoute,
  onDemandCategoryRoute,
  workoutOverviewRoute,
  challengesSeriesRoute,
  finisherOverviewRoute,
  viewAllChallengesRoute,
  challengeOverviewRoute,
  recipeOverviewRoute,
  chooseMealsRoute,
  transformationPhotosRoute,
  preferencesRoute,
  workoutCompleteRoute,
  subscriptionUpdateRoute,
  workoutHistoryRoute,
  measurementsRoute,
  resourceRoute,
  ListOnDemandWorkoutTypeRoute,
  ListOnDemandWorkoutClassRoute,
  ListOnDemandWorkoutFavoriteRoute,
  adminRoute,
  achievementRoute,
]

export const sideNavRoutes: Array<RouteType> = [
  todayRoute,
  onDemandRoute,
  viewAllChallengesRoute,
  plannerRoute,
  recipesRoute,
  squadsRoute,
  achievementRoute,
  profileRoute,
  adminRoute,
]
export const sideNavRoutesObj = {
  today: todayRoute,
  onDemand: onDemandRoute,
  viewAllChallenges: viewAllChallengesRoute,
  planner: plannerRoute,
  recipes: recipesRoute,
  squads: squadsRoute,
  achievement: achievementRoute,
  profile: profileRoute,
  admin: adminRoute,
}
