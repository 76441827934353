import React from 'react'
import styles from './components.module.scss'
import { Discount, SubscriptionOptionType } from 'types'
import RedPill from 'features/SignUp/components/RedPill'

type SubscriptionOptionProps = {
  option: SubscriptionOptionType
  onClick: (option: SubscriptionOptionType) => void
  isActive: boolean
  showPromo?: boolean
  isPromo?: boolean
  isDiscounted?: boolean
  discountToDisplay?: Discount
}

export const SubscriptionOption: React.FC<SubscriptionOptionProps> = ({ option, onClick, isActive, showPromo, isPromo = false, isDiscounted, discountToDisplay }) => {
  const displayPromo = (showPromo && isPromo) || isDiscounted
  const discount = displayPromo
    ? isPromo
      ? Math.round(((option.price.unit_amount / 100 - (option.promoPrice || 0)) / (option.price.unit_amount / 100)) * 100)
      : discountToDisplay?.discountPercentage || 0
    : 0

  const oldPrice = isPromo ? option.price.unit_amount / 100 : isDiscounted && discountToDisplay ? discountToDisplay.oldPrice : option.price.unit_amount / 100

  const price = isPromo ? option.promoPrice || option.price.unit_amount / 100 : isDiscounted && discountToDisplay ? discountToDisplay.newPrice : option.price.unit_amount / 100

  return (
    <button className="w-full" onClick={() => onClick(option)}>
      <div className="flex flex-col relative">
        {displayPromo && (
          <div className="absolute top-1 right-1">
            <RedPill active={false}>{discount}% off!</RedPill>
          </div>
        )}
        <div
          className={`
          hover:bg-slate-100 rounded-lg p-1 shadow-md
          ${isActive ? 'bg-slate-200' : ''}
          border border-slate-700 w-min-full
          ${displayPromo ? 'pt-8' : ''}
        `}
        >
          <div className={styles.horizontalContainer}>
            <div className="flex">
              <p className={`${isActive ? 'font-extrabold text-slate-700' : 'font-semibold text-slate-500'} text-4xl`}>{option.period}</p>
              <p className={`${isActive ? 'font-extrabold text-slate-700' : 'font-semibold text-slate-500'} pl-3 text-4xl`}>${price}</p>
            </div>
          </div>
          {displayPromo && <p className="font-semibold text-slate-500 text-xxs ml-3">{isDiscounted ? `Renews at ${oldPrice}` : option.promoText}</p>}
        </div>
      </div>
    </button>
  )
}
