import React, { useState, useEffect, useMemo } from 'react'
import { useSignUpContext } from '../SignUpContext'
import FormWrapper from './FormWrapper'
import RedPill from './RedPill'
import BluePill from './BluePill'
import BlackPill from './BlackPill'
import { PromoCodeUtils } from 'shared/functions/'
import { usePromoCodes } from 'hooks/usePromoCodes'
import { PromoCode, SubscriptionOptionType, Discount } from 'types'
import { useCountry } from 'hooks/useCountry'
import { isDefaultPrice } from 'shared/functions/stripe'

const PlanSelector: React.FC = () => {
  const { data: promoCodes, isLoading: promoCodesLoading } = usePromoCodes()
  const { data: country } = useCountry()
  const displayedCurrency = country?.countryCode === 'CA' ? 'CAD' : 'USD'

  const { options, selectedPlan, onSelectPlan, onSubmitPlan, promoCode, setPromoCode, availablePlans } = useSignUpContext()
  const [promoCodeIsValid, setPromoCodeIsValid] = useState<boolean>(false)
  const [promoCodeStripeData, setPromoCodeStripeData] = useState<PromoCode | null>(null)
  const [discountToDisplay, setDiscountToDisplay] = useState<Discount | null>(null)
  const { activePlans } = availablePlans || {}
  useEffect(() => {
    if (promoCode && promoCodes) {
      onPromoCodeChange(promoCode)
      // find the promo codes out of the collection
      const promoCodeTarget = promoCodes.find((promoCodeItem) => promoCodeItem.code === promoCode)
      if (!promoCodeTarget) {
        console.log('promoCodeTarget not found :>> ', promoCodeTarget)
        return
      }
      setPromoCodeStripeData(promoCodeTarget)
      console.log('promoCodeTarget :>> ', promoCodeTarget)
      const plansArray = [monthlyPlan, quarterlyPlan, annualPlan] as SubscriptionOptionType[]

      try {
        const discount = PromoCodeUtils.buildDiscountObject(plansArray, promoCode, promoCodes, displayedCurrency)
        setDiscountToDisplay(discount)
      } catch (error) {
        console.error('Failed to build discount:', error)
        setDiscountToDisplay(null)
      }
    }
  }, [promoCode, promoCodes, displayedCurrency])

  const onPromoCodeChange = (code: string) => {
    if (!promoCodes) return
    const isValid = PromoCodeUtils.validatePromoCode(code, promoCodes)
    setPromoCodeIsValid(isValid)
  }

  const renderPromoValidity = () => {
    if (promoCodeIsValid && promoCode !== '') {
      return (
        <div className="text-xs text-green-500 font-bold">
          <span className="text-green-500">✓ </span> {PromoCodeUtils.getDescription(promoCode, promoCodes)}
        </div>
      )
    }
    if (promoCode && !promoCodeIsValid) {
      return (
        <div className="text-xs text-red-500 font-bold">
          <span className="text-red-500">✗</span> Invalid Promo Code
        </div>
      )
    }
    return null
  }

  if (promoCodesLoading || !options || !availablePlans) {
    return (
      // show a loading spinner
      <div className="flex justify-center items-center">
        <div className="text-xs font-semibold text-gray-900">Loading...</div>
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    )
  }

  const showPromoMonthly = promoCodeStripeData?.metadata.target === 'monthly'
  const showPromoQuarterly = promoCodeStripeData?.metadata.target === 'quarterly'
  const showPromoAnnual = promoCodeStripeData?.metadata.target === 'yearly'

  if (!availablePlans || !activePlans) {
    return null
  }

  const { monthlyPlan, quarterlyPlan, annualPlan } = activePlans
  const activeMonthly = monthlyPlan && monthlyPlan.type && selectedPlan === monthlyPlan.type
  const activeQuarterly = quarterlyPlan && quarterlyPlan.type && selectedPlan === quarterlyPlan.type
  const activeYearly = annualPlan && annualPlan.type && selectedPlan === annualPlan.type

  return (
    <FormWrapper step={1} title="Choose your plan!">
      <h2 className="text-xs font-semibold">Start your Lean Journey totally free! Pay nothing today. You will be charged after the trial ends unless cancelled prior!</h2>
      {/* MONTHLY */}
      <div
        className={`border-2 hover:border-red-800 cursor-pointer border-B5B5B5 shadow-md rounded-3xl p-4 mt-4  ${showPromoMonthly ? 'h-26' : 'h-30'}    ${
          activeMonthly ? 'bg-red-500' : ''
        }  p-4 `}
        onClick={() => {
          if (monthlyPlan) {
            onSelectPlan(monthlyPlan.type, monthlyPlan.price)
          }
        }}
      >
        <div className={` flex flex-col    `}>
          <div className={`flex ${showPromoMonthly ? ' justify-end ' : ''} relative`}>
            {showPromoMonthly && (
              <RedPill active={activeMonthly || false} className=" -rotate-6 absolute max-h-7 -mr-2 -mt-2  ">
                {discountToDisplay?.discountPercentage}% OFF!
              </RedPill>
            )}
            {!showPromoMonthly && (
              <div className={`text-sm w-full  ${activeMonthly ? 'text-slate-50' : ' text-coral-darkest'} font-bold  italic opacity-70 text-right`}> 3 day free trial</div>
            )}
          </div>

          <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} flex font-bold ${showPromoMonthly ? 'mt-6 ' : 'mt-0'}`}>
            {showPromoMonthly && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50 line-through' : ' line-through text-slate-900'} text-xs  font-bold mr-1 `}>
                  ${((monthlyPlan?.price.unit_amount || 0) / 100 / 30).toFixed(2)}/day
                </div>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xs  font-bold `}>Only ${((discountToDisplay?.newPrice || 0) / 30).toFixed(2)}/day</div>
              </>
            )}
            {!showPromoMonthly && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xs  font-bold `}>
                  Only ${((monthlyPlan?.price.unit_amount || 0) / 100 / 30).toFixed(2)}/day
                </div>
              </>
            )}
          </div>
          <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} `}>
            {showPromoMonthly && (
              <span>
                <span className="pl-2 line-through text-xl tracking-tight font-bold pb-2">${(monthlyPlan?.price.unit_amount || 0) / 100}</span>
                <span className="pl-2 text-xl tracking-tight font-bold pb-2">
                  ${discountToDisplay?.newPrice || 0}
                  {displayedCurrency === 'CAD' ? ' CAD' : ''} Monthly{' '}
                </span>
              </span>
            )}
            {!showPromoMonthly && (
              <span>
                <span className="pl-2 text-xl tracking-tight font-bold pb-2">
                  ${(monthlyPlan?.price.unit_amount || 0) / 100}
                  {displayedCurrency === 'CAD' ? ' CAD' : ''} Monthly
                </span>
              </span>
            )}
            {showPromoMonthly && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>
                  {discountToDisplay?.discountPercentage}% off for {promoCodeStripeData?.coupon.duration === 'forever' ? 'life' : '6 months'}, then renews at $
                  {(monthlyPlan?.price.unit_amount || 0) / 100}
                  {displayedCurrency === 'CAD' ? ' CAD' : ''}/month
                </div>
              </>
            )}
            {!showPromoMonthly && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>
                  Renews at ${(monthlyPlan?.price.unit_amount || 0) / 100}
                  {displayedCurrency === 'CAD' ? ' CAD' : ''}/month
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* QUARTERLY */}
      <div
        className={`${activeQuarterly ? 'bg-red-500' : ''} border-2 cursor-pointer hover:border-red-800  border-B5B5B5 shadow-md  h-35 rounded-3xl p-4 mt-3`}
        onClick={() => {
          if (quarterlyPlan) {
            onSelectPlan(quarterlyPlan.type, quarterlyPlan.price)
          }
        }}
      >
        <div className="flex justify-between">
          <BluePill active={activeQuarterly || false}>Most Popular</BluePill>
          {!showPromoQuarterly && (
            <div className={`text-sm   ${activeQuarterly ? 'text-slate-50' : ' text-coral-darkest'} font-bold  italic opacity-70 text-right`}> 3 day free trial</div>
          )}

          {showPromoQuarterly && (
            <RedPill active={activeQuarterly || false} className=" -rotate-6 max-h-7 -mr-2 ">
              {discountToDisplay?.discountPercentage}% OFF!
            </RedPill>
          )}
        </div>
        <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} flex justify-between font-bold`}>
          <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} text-xs  `}>
            {showPromoQuarterly && (
              <>
                <span className="pl-2 line-through text-2xl tracking-tight font-bold pb-2">${((quarterlyPlan?.price.unit_amount || 0) / 100 / 90).toFixed(2)}/day</span>
                <span className="ml-1">Only ${((discountToDisplay?.newPrice || 0) / 90).toFixed(2)}/day</span>
              </>
            )}
            {!showPromoQuarterly && (
              <>
                <span className="pl-2 text-2xl tracking-tight font-bold pb-2">${((quarterlyPlan?.price.unit_amount || 0) / 100 / 90).toFixed(2)}/day</span>
              </>
            )}
          </div>
        </div>
        <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} `}>
          {showPromoQuarterly && (
            <>
              <span className="pl-2 line-through text-xl tracking-tight font-bold pb-2">${(quarterlyPlan?.price.unit_amount || 0) / 100}</span>
              <span className="pl-2 text-xl tracking-tight font-bold pb-2">
                ${discountToDisplay?.newPrice || 0}
                {displayedCurrency === 'CAD' ? ' CAD' : ''} Quarterly{' '}
              </span>
            </>
          )}
          {!showPromoQuarterly && (
            <>
              <span className="pl-2 text-xl tracking-tight font-bold pb-2">
                ${(quarterlyPlan?.price.unit_amount || 0) / 100}
                {displayedCurrency === 'CAD' ? ' CAD' : ''} Quarterly
              </span>
            </>
          )}
          <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>
            Renews at ${(quarterlyPlan?.price.unit_amount || 0) / 100}
            {displayedCurrency === 'CAD' ? ' CAD' : ''}/quarter
          </div>
        </div>
      </div>

      {/* ANNUAL */}
      <div
        className={`${activeYearly ? 'bg-red-500' : ''} border-2 cursor-pointer hover:border-red-800 shadow-md border-B5B5B5 h-35 rounded-3xl p-4 mt-3`}
        onClick={() => {
          if (annualPlan) {
            onSelectPlan(annualPlan.type, annualPlan.price)
          }
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-row  justify-between">
            <BluePill active={activeYearly || false}>Best Value!</BluePill>
            <div className={`text-sm ${activeYearly ? 'text-slate-50' : ' text-coral-darkest'}    font-bold  italic opacity-70  `}>3 day free trial</div>
          </div>
        </div>

        {showPromoAnnual && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} flex justify-between font-bold mt-2`}>
            <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} text-xs `}>Only ${((annualPlan?.price.unit_amount || 0) / 100 / 365).toFixed(2)}/day</div>
          </div>
        )}
        {!showPromoAnnual && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} flex justify-between font-bold mt-2`}>
            <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} text-xs `}>Only ${((annualPlan?.price.unit_amount || 0) / 100 / 365).toFixed(2)}/day</div>
          </div>
        )}

        {showPromoAnnual && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} `}>
            <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} flex items-center`}>
              <span className="pl-2 line-through text-xl tracking-tight font-bold pb-2">${(annualPlan?.price.unit_amount || 0) / 100}</span>
              <span className="pl-2 text-xl tracking-tight font-bold pb-2">
                ${discountToDisplay?.newPrice || 0}
                {displayedCurrency === 'CAD' ? ' CAD' : ''} Annually{' '}
              </span>
            </div>
            <div className={`text-xxs pb-2`}>
              Renews at ${((annualPlan?.price.unit_amount || 0) / 100 / 365).toFixed(2)}
              {displayedCurrency === 'CAD' ? ' CAD' : ''}/day
            </div>
          </div>
        )}
        {!showPromoAnnual && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} `}>
            <span className="pl-2 text-xl tracking-tight font-bold pb-2">
              ${(annualPlan?.price.unit_amount || 0) / 100}
              {displayedCurrency === 'CAD' ? ' CAD' : ''} Annually{' '}
            </span>
            <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} text-xxs pb-2`}>
              Renews at ${(annualPlan?.price.unit_amount || 0) / 100}
              {displayedCurrency === 'CAD' ? ' CAD' : ''}/year
            </div>
          </div>
        )}
      </div>
      {/* PromoCodes */}
      <div className="mt-4">
        <div className="flex flex-row justify-between">
          <div className="text-xs font-bold">Promo Code</div>
        </div>
        <input
          className="w-full border-2 border-B5B5B5 rounded-md p-2 mt-2"
          type="text"
          placeholder="Enter Promo Code"
          value={promoCode}
          onChange={(e) => {
            setPromoCode(e.target.value)
          }}
        />
        {renderPromoValidity()}
      </div>

      <div className={`mt-4 bg-slate-100 text-xs font-semibold p-2`}>
        <p> Renewed monthly/quarterly/annually. All prices in {displayedCurrency}.</p>
      </div>
      <button
        className={`  bg-gradient-to-r  disabled:bg-slate-300 shadow-md hover:ring ring-coral-darkest disabled:ring-0  ${
          selectedPlan ? ' from-coral to-coral-darker text-slate-50  shadow-coral-darkest' : 'text-black'
        } w-full  font-extrabold text-lg my-4  h-12 rounded-md`}
        disabled={!selectedPlan}
        onClick={onSubmitPlan}
      >
        Continue
      </button>
    </FormWrapper>
  )
}

export default PlanSelector
