import { Button } from 'shared/components'
import { SubscriptionOption } from './SubscriptionOption'
import { useSignUpContext } from 'features/SignUp/SignUpContext'
import React, { useMemo } from 'react'
import { buildSubscriptionsFromPrices } from 'features/Subscription/utils'
import { useStripeSubscription } from 'hooks/useStripeSubscription'
import { AuthStateContext } from 'context'
import { Discount } from './Discount'

interface currentSubscriptionProps {
  activeSubscription: any
  onClickUpdatePaymentMethod: () => void
  onClickUpdatePlan: () => void
  onClickCancelSubscription: () => void
  renewsOnDate: string
  cancelAtPeriodEnd: boolean
}

const getSubscriptionType = (activeSubscription: any): string => {
  if (activeSubscription)
    if (activeSubscription.type !== 'monthly') {
      return activeSubscription.type
    }

  if (activeSubscription.description == '$19.99') return 'legacyMonthly'
  return 'monthly'
}

/**
 *
 * Current Subscription Component
 */
const CurrentSubscription = ({
  activeSubscription,
  renewsOnDate,
  onClickCancelSubscription,
  onClickUpdatePaymentMethod,
  onClickUpdatePlan,
  cancelAtPeriodEnd,
}: currentSubscriptionProps) => {
  const { state: authState } = React.useContext(AuthStateContext)
  const { data: stripeSubscription, isLoading: isLoadingStripe } = useStripeSubscription(authState.user?.id || '')

  const { prices } = useSignUpContext()
  if (!activeSubscription)
    return (
      <>
        <Button onClick={onClickUpdatePlan} label="Update Plan" containerStyle="mt-4 bg-white border border-green-700 text-green-700" />
      </>
    )

  // get the price from the active subscription
  const price = activeSubscription.price
  // find the matching price from usePrices
  const matchingPrice = prices?.find((p) => p.id === price)
  // pull the product id from the matching price
  const productId = matchingPrice?.product

  // pull the type from the product id
  const type = getSubscriptionType(activeSubscription)

  return (
    <>
      <p className="font-bold text-gray-600 mb-2">Current Subscription</p>
      <SubscriptionOption
        key={'currentSubscription'}
        option={activeSubscription}
        isActive={true}
        onClick={() => {
          //
        }}
      />
      {stripeSubscription && stripeSubscription.stripe_details?.discount && (
        <>
          <p className="font-bold text-gray-600 mb-1 mt-2">Promotion Applied</p>
          <Discount subscription={stripeSubscription} />
        </>
      )}
      {cancelAtPeriodEnd && <p className="text-sm font-bold text-gray-600 ml-2 mt-2">Your subscription will cancel at the end of the current billing period.</p>}
      <p className="text-sm font-bold text-gray-600 ml-2 mt-2">Your Current Billing Period ends on: {renewsOnDate}.</p>
      <Button onClick={onClickUpdatePaymentMethod} label="Update Payment Method" containerStyle="mt-4 bg-white border border-green-700 text-green-700" />
      <Button onClick={onClickUpdatePlan} label={cancelAtPeriodEnd ? 'Reactivate Account' : 'Update Plan'} containerStyle="mt-4 bg-white border border-green-700 text-green-700" />
      <Button
        onClick={onClickCancelSubscription}
        label={cancelAtPeriodEnd ? 'Cancelled' : 'Cancel Subscription'}
        containerStyle="mt-4 bg-red-500 border border-red-500 text-white disabled:bg-slate-500 disabled:border-slate-900 disabled:cursor-not-allowed"
        disabled={cancelAtPeriodEnd}
      />
    </>
  )
}

export { CurrentSubscription }
