import React from 'react'
import { Button } from 'shared/components'
import styles from './ChallengeOverview.module.scss'

type CreateUserChallengeButtonProps = {
  onClick: () => void
  cancelOrSchedule?: boolean
}

const CreateUserChallengeButton = ({ onClick, cancelOrSchedule }: CreateUserChallengeButtonProps) => {
  const label = !!cancelOrSchedule && cancelOrSchedule ? 'Cancel Current Challenge Or Schedule' : 'Start Challenge'
  return (
    <>
      <Button containerStyle={`${styles.selectPlanBtn} bg-coral mb-4 lg:max-w-lg w-full md:w-[70%] mx-auto disabled:bg-gray-600`} onClick={onClick} label={label} />
    </>
  )
}

export { CreateUserChallengeButton }
