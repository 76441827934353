import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './ChallengeOverview.module.scss'
import { Box, LoadingIndicator } from 'shared/components'
import { Challenge } from 'types'
import { useLocation, useParams } from 'react-router'
import { useQuery } from 'react-query'
import { getChallengeById } from 'services/api/challenges'
import { ReactComponent as Squads } from 'assets/images/squads-header.svg'
import { ReactComponent as Check } from 'assets/icons/check.svg'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { ChallengeUtils } from '../../../shared/functions'
import RedPill from '../../SignUp/components/RedPill'
import { CancelChallengeButton } from './CancelChallengeButton'
import { ComingSoonButton } from './ComingSoonButton'
import { ScheduleLegacyUserChallengeModal } from './ScheduleLegacyUserChallengeModal'
import { ScheduleFlexUserChallengeModal } from './ScheduleFlexUserChallengeModal'
import { CreateUserChallengeButton } from './CreateUserChallengeButton'

type ChallengeOverviewParams = { challengeId: string }
type ChallengeOverviewLocationState = { challenge?: Challenge }

export const ChallengeOverview: React.FC = () => {
  const { state: locationState = {} } = useLocation<ChallengeOverviewLocationState>()
  const { challengeId = '' } = useParams<ChallengeOverviewParams>()
  const [challenge, setChallenge] = useState<Challenge | undefined>(locationState.challenge)
  const challengeQuery = useQuery(['challengeById', challengeId], () => getChallengeById(challengeId), { enabled: challenge === undefined })
  const { state: challengeState } = useContext(UserChallengeContext)
  const { activeChallenge } = challengeState
  const { data: challengeData, isLoading: challengeLoading } = challengeQuery
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const isQueueable = useMemo(() => challenge && ChallengeUtils.isQueueable(challenge.releaseDate), [challenge])
  const isReleased = useMemo(() => challenge && ChallengeUtils.isReleased(challenge.releaseDate), [challenge])
  useEffect(() => {
    if (!challenge && challengeData) {
      console.log('Setting Challenge from Query :>> ', challengeData)
      setChallenge(challengeData)
    }
  }, [challenge, challengeData])

  if (!challenge || challengeLoading) {
    return <LoadingIndicator />
  }

  const numberOfDays = challenge.numberOfWeeks ? challenge.numberOfWeeks * 7 : challenge.totalWorkouts
  const isFlex = challenge.type?.title === 'Flex'
  const isInAChallenge = activeChallenge && activeChallenge.challenge_id
  const isAlreadyInThisChallenge = isInAChallenge && activeChallenge.challenge_id === challenge.id

  const showCancel = isAlreadyInThisChallenge
  const showChangeToOrSchedule = isInAChallenge

  return (
    <>
      {/* prettier-ignore*/}
      <>
        {showScheduleModal && (isFlex 
          ? <ScheduleFlexUserChallengeModal challenge={challenge} setIsOpen={setShowScheduleModal} isInAChallenge={showWarning} />
          : <ScheduleLegacyUserChallengeModal challenge={challenge} setIsOpen={setShowScheduleModal} isInAChallenge={showWarning} />
        )}
      </>
      <Box className={`${styles.container} mx-10`}>
        <Box className={styles.imageContainer}>
          <img src={challenge.image} />
        </Box>
        <Box className={styles.header}>
          <p className={styles.title}>{challenge.title}</p>
          <p className={styles.metadata}>{`${numberOfDays} Days${challenge.equipment ? ` • With Weights` : ' • Bodyweight Only'}`}</p>
        </Box>
        <Box className={styles.content}>
          {!isReleased && (
            <RedPill active={false} className=" border-black border-2 px-8 mx-auto border-solid">
              Challenge Launches {new Date(challenge.releaseDate).toLocaleString('en-US', { month: 'short', day: 'numeric' })}
            </RedPill>
          )}
          {/* prettier-ignore*/}
          <div>
            {!isReleased && !isQueueable ? <ComingSoonButton />
            : isAlreadyInThisChallenge ? <CancelChallengeButton currentUserChallengeId={activeChallenge.id} redirect={false} />
            : isInAChallenge ? <CreateUserChallengeButton cancelOrSchedule={true} onClick={() =>{ setShowWarning(true); setShowScheduleModal(true)}} />
            : challengeLoading ? <LoadingIndicator />
            : <CreateUserChallengeButton onClick={() => {
               
                setShowScheduleModal(true)
                } }/>}
          </div>
          <p className={styles.contentTitle}>CHALLENGE SUMMARY</p>
          <p className={styles.challengeSummary}>{challenge.summary}</p>
          <p className={styles.contentTitle}>CHALLENGE INCLUDES:</p>
          <div className="grid grid-cols-1 md:grid-cols-2">
            {challenge.includes.map((item) => (
              <div key={item} className={`${styles.includesItem} flex`}>
                <div className="item"></div>
                <div className={`${styles.checkContainer} justify-center flex`}>
                  <Check />
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>
          <Box className={styles.banner}>
            <Squads />
            <p>Join the Squad and become better than ever!</p>
          </Box>
        </Box>
      </Box>
    </>
  )
}
