import React, { useEffect, useState } from 'react'
import { Box, Button } from 'shared/components'
import styles from '../Subscription.module.scss'
import { SubscriptionOptionList } from '../SubscriptionOptionList'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { AuthState, CurrentViewOptions } from '../../../types/index'
import { usePromoCodes } from 'hooks/usePromoCodes'
import { SubscriptionOptionType, Discount } from 'types'
import { PromoCodeUtils } from 'shared/functions'
import { useSignUpContext } from 'features/SignUp/SignUpContext'

interface UpdatePlanProps {
  subscriptionOptions: SubscriptionOptionType[]
  selectedOption?: SubscriptionOptionType
  onClickOption: (option: any) => void // Replace 'any' with the proper type
  onClickAction: () => void
  showPromoCodeContactUs: boolean
  changeView: (view: CurrentViewOptions, reason: string) => void
  authState: AuthState
  activeSubscription: SubscriptionOptionType | undefined
  currentView: CurrentViewOptions
  selectedPromoCode: string
  setSelectedPromoCode: (code: string) => void
  selectedCouponId: string
  setSelectedCouponId: (id: string) => void
  planOptions: SubscriptionOptionType[]
}

const UpdatePlan: React.FC<UpdatePlanProps> = ({
  subscriptionOptions,
  activeSubscription,
  selectedOption,
  onClickOption,
  onClickAction,
  showPromoCodeContactUs,
  changeView,
  authState,
  currentView,
  selectedPromoCode,
  setSelectedPromoCode,
  setSelectedCouponId,
  planOptions,
}) => {
  const subscription = authState.user?.subscription
  // const [selectedPromoCode, setSelectedPromoCode] = useState<string>('')
  // const showPromos = true
  const { data: promoCodes, isLoading: promoCodesLoading } = usePromoCodes()
  const { displayedCurrency } = useSignUpContext()

  const [promoCodeIsValid, setPromoCodeIsValid] = useState<boolean>(false)
  const [discountToDisplay, setDiscountToDisplay] = useState<Discount>()

  useEffect(() => {
    if (selectedPromoCode) {
      console.log(selectedPromoCode)
      onPromoCodeChange(selectedPromoCode)
    }
  }, [selectedPromoCode])

  const onPromoCodeChange = (code: string) => {
    if (!promoCodes) return

    const isValid = PromoCodeUtils.validatePromoCode(code, promoCodes)

    if (isValid) {
      console.log(`Setting discount to display`)
      const discount = PromoCodeUtils.buildDiscountObject(planOptions, code, promoCodes, displayedCurrency)
      setDiscountToDisplay(discount)
      const couponId = PromoCodeUtils.couponIdFromPromoCode(selectedPromoCode, promoCodes)
      if (couponId) {
        setSelectedCouponId(couponId)
      }
    }
    if (!isValid) {
      setDiscountToDisplay(undefined)
    }

    setPromoCodeIsValid(isValid)
  }

  const renderPromoValidity = () => {
    if (promoCodeIsValid) {
      return (
        <div className="text-xs text-green-500 font-bold">
          <span className="text-green-500">✓ </span> {PromoCodeUtils.getDescription(selectedPromoCode, promoCodes)}
        </div>
      )
    }
    if (selectedPromoCode && !promoCodeIsValid) {
      return (
        <div className="text-xs text-red-500 font-bold">
          <span className="text-red-500">✗</span> Invalid Promo Code
        </div>
      )
    }
    return null
  }

  return (
    <>
      {activeSubscription && (
        <div className={`mt-2`}>
          <button onClick={() => changeView(null, '')}>
            <ArrowLeft />
          </button>
        </div>
      )}
      <Box className={`${styles.optionsContainer} text-center`}>
        <p className={` text-xs`}>
          By changing your subscription, you will only be charged the prorated amount for your chosen subscription. Your subscription will renew at the full amount at the end of
          your new billing cycle. If there are any issues please contact us at{' '}
          <a href="mailto:info@lean-squad.com  " className={`${styles.mailToLink} mt-2`}>
            info@lean-squad.com{' '}
          </a>{' '}
        </p>

        <SubscriptionOptionList
          activeSubscription={activeSubscription}
          subscriptionOptions={planOptions}
          selectedOption={selectedOption}
          showPromos={true}
          onClickOption={onClickOption}
          discountToDisplay={discountToDisplay}
        />
      </Box>

      <Button disabled={!selectedOption} onClick={onClickAction} label={currentView === 'updatePlan' ? 'Update Plan' : 'Select Plan'} />

      {/* PromoCodes */}
      {/* <div className="mt-4">
        <div className="flex flex-row justify-between">
          <div className="text-xs font-bold">Promo Code</div>
        </div>
        <input
          className="w-full border-2 border-B5B5B5 rounded-md p-2 mt-2"
          type="text"
          placeholder="Enter Promo Code"
          value={selectedPromoCode}
          onChange={(e) => {
            const code = PromoCodeUtils.normalizeCode(e.target.value)
            setSelectedPromoCode(code)
          }}
        />

        {renderPromoValidity()}
      </div> */}
      {showPromoCodeContactUs && (
        <>
          <h2 className={`text-xl font-bold   font-SfProDisplayBold mt-4  text-center`}> Promotion Code Eligibility</h2>
          <p className="mt-2">
            If you are interested in the promo code on my social media and would like to check if you qualify for the discount, please reach out to us at{' '}
            <a href="mailto:info@lean-squad.com" className="font-semibold">
              info@lean-squad.com
            </a>
            !
          </p>
        </>
      )}
      {!showPromoCodeContactUs && null}
    </>
  )
}

export { UpdatePlan }
