import { Discount, SubscriptionOptionType } from 'types'

import React, { useContext, useEffect, useState, useMemo } from 'react'
import { SubscriptionOption } from './components/SubscriptionOption'

import * as StripeUtils from 'shared/functions/stripe'
import { AuthStateContext } from 'context'
import { useSignUpContext } from 'features/SignUp/SignUpContext'

type SubscriptionOptionListProps = {
  activeSubscription: SubscriptionOptionType | undefined
  subscriptionOptions: SubscriptionOptionType[]
  selectedOption: SubscriptionOptionType | undefined
  showPromos: boolean
  onClickOption: (option: SubscriptionOptionType) => void
  discountToDisplay?: Discount
}

export function SubscriptionOptionList({ activeSubscription, subscriptionOptions, selectedOption, showPromos, onClickOption, discountToDisplay }: SubscriptionOptionListProps) {
  const { state: authState } = useContext(AuthStateContext)
  const { availablePlans } = useSignUpContext()
  const { user } = authState
  const [monthlyPromo, setMonthlyPromo] = useState<string | undefined>(undefined)
  const [quarterlyPromo, setQuarterlyPromo] = useState<string | undefined>(undefined)
  const [yearlyPromo, setYearlyPromo] = useState<string | undefined>(undefined)

  // Commented out till we revisit and restore in app promocodes

  // useEffect(() => {
  //   // determine the best monthly promo but not if a discount is already applied
  //   if (user && !discountToDisplay) {
  //     StripeUtils.calculateBestPromo(StripeUtils.monthlyPromoList, user.id).then((promo) => {
  //       if (promo) setMonthlyPromo(promo)
  //     })

  //     // determine the best quarterly promo
  //     StripeUtils.calculateBestPromo(StripeUtils.quarterlyPromoList, user.id).then((promo) => {
  //       if (promo) setQuarterlyPromo(promo)
  //     })

  //     // determine the best yearly promo
  //     StripeUtils.calculateBestPromo(StripeUtils.yearlyPromoList, user.id).then((promo) => {
  //       if (promo) setYearlyPromo(promo)
  //     })
  //   }
  // }, [user, discountToDisplay])

  const hasAnEligiblePromo = useMemo(() => {
    return null

    return monthlyPromo || quarterlyPromo || yearlyPromo
  }, [monthlyPromo, quarterlyPromo, yearlyPromo])

  if (!availablePlans || !availablePlans.activePlans) {
    console.error('SubscriptionOptionList: missing subscription options')
    return null
  }

  // don't show option type that the user already had from the availablePlans  if activeSubscription.type is monthly don't show monthly option
  const ShowMonthly = activeSubscription?.type !== 'monthly'
  const ShowQuarterly = activeSubscription?.type !== 'quarterly'
  const ShowYearly = activeSubscription?.type !== 'yearly'

  const { monthlyPlan, quarterlyPlan, annualPlan } = availablePlans.activePlans

  if (!monthlyPlan || !quarterlyPlan || !annualPlan) {
    console.error('SubscriptionOptionList: missing subscription plan')
    return null
  }

  const isActive = (option: SubscriptionOptionType) => {
    return selectedOption?.type === option.type
  }

  return (
    <div className="space-y-4 mt-8">
      {hasAnEligiblePromo && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2">
            <div className="w-4 h-4 rounded-full bg-green-500" />
            <div className="text-sm text-gray-500">{`You're eligible for a discount!`}</div>
          </div>
        </div>
      )}
      {ShowMonthly && (
        <SubscriptionOption
          key={'subMonthlyOption'}
          showPromo={showPromos}
          isPromo={!!monthlyPromo}
          option={monthlyPlan}
          isActive={isActive(monthlyPlan)}
          onClick={onClickOption}
          isDiscounted={discountToDisplay?.targetPlan === 'monthly'}
          discountToDisplay={discountToDisplay?.targetPlan === 'monthly' ? discountToDisplay : undefined}
        />
      )}
      {ShowQuarterly && (
        <SubscriptionOption
          key={'subQuarterlyOption'}
          showPromo={showPromos}
          isPromo={!!quarterlyPromo}
          option={quarterlyPlan}
          isActive={isActive(quarterlyPlan)}
          onClick={onClickOption}
          isDiscounted={discountToDisplay?.targetPlan === 'quarterly'}
          discountToDisplay={discountToDisplay?.targetPlan === 'quarterly' ? discountToDisplay : undefined}
        />
      )}
      {ShowYearly && (
        <SubscriptionOption
          key={'subYearlyOption'}
          showPromo={showPromos}
          isPromo={!!yearlyPromo}
          option={annualPlan}
          isActive={isActive(annualPlan)}
          onClick={onClickOption}
          isDiscounted={discountToDisplay?.targetPlan === 'yearly'}
          discountToDisplay={discountToDisplay?.targetPlan === 'yearly' ? discountToDisplay : undefined}
        />
      )}
    </div>
  )
}
