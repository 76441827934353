import { Price, SubscriptionOptionType } from 'types'
import { Product, PromoCode, Discount } from 'types'

import { getDetails } from './stripe'
import { limitFloatToTwoDecimals } from './utils'
import { useSignUpContext } from 'features/SignUp/SignUpContext'

const normalizeCode = (code: string): string => {
  return code.trim().toUpperCase()
}

const validatePromoCode = (promoCode: string, promoCodeList: PromoCode[]) => {
  if (!promoCodeList?.length || !promoCode) {
    return false
  }

  const normalizedCode = normalizeCode(promoCode)

  if (normalizedCode.length <= 3) {
    return false
  }

  const codeList = promoCodeList.map((item) => item.code)
  return codeList.includes(normalizedCode)
}

const getDescription = (promoCode: string, promoCodeList: PromoCode[] | undefined) => {
  if (!promoCodeList) {
    return ''
  }

  const promoCodeItem = promoCodeList.find((item) => item.code === promoCode)
  console.log('promoCodeItem', promoCodeItem)
  if (promoCodeItem) {
    console.log('promoCodeItem.metadata.desc', promoCodeItem.metadata.desc)
    return promoCodeItem.metadata.desc
  }
  return ''
}

const promoAppliesToProduct = (promoCode: PromoCode, product: Product) => {
  console.log('confirming promo applies to product')
  console.log('promoCode', promoCode)
  console.log('product', product)

  // Guard checks
  if (!promoCode || !product || !promoCode.coupon) return false

  // currency
  const { displayedCurrency } = useSignUpContext()

  // get id from product
  const productId = product.id

  // check if promo applies to product
  const itDoesApply = promoCode.coupon.applies_to.products.includes(productId)

  return itDoesApply
}

const couponIdFromPromoCode = (promoCode: string, promoCodes: PromoCode[]) => {
  if (!promoCode) return undefined

  const promoCodeItem = promoCodes.find((item) => item.code === promoCode)
  if (promoCodeItem) {
    return promoCodeItem.coupon.id
  }
  return undefined
}

const getPromoFromCode = (promoCode: string, promoCodeList: PromoCode[] | undefined) => {
  if (!promoCodeList) {
    return
  }

  const promoCodeItem = promoCodeList.find((item) => item.code === promoCode)
  if (promoCodeItem) {
    return promoCodeItem
  }
  return
}

const getPriceAfterDiscount = (selectedPrice: Price, selectedProduct: Product, promo: PromoCode) => {
  // does the promo apply to the product?
  const isApplicable = promoAppliesToProduct(promo, selectedProduct)
  // get the price of the product
  const basePrice = selectedPrice.unit_amount || 0
  // get the discount amount
  const discount = promo.coupon.amount_off
  // calculate the final price
  const finalPrice = basePrice - discount
  // return the final price
  return finalPrice
}

const findByCode = (code: string, promoCodes: PromoCode[]): PromoCode | undefined => {
  return promoCodes.find((promoCode) => promoCode.code === code)
}

const appliesToPlan = (promoCode: PromoCode, plan: string): boolean => {
  return promoCode.metadata.target === plan
}

const getDiscount = (promoCode: PromoCode): number => {
  return promoCode.coupon.amount_off ?? 0
}

const buildDiscountObject = (availablePlans: SubscriptionOptionType[], promoCode: string, promoCodes: PromoCode[], currency = 'USD') => {
  const discount: Discount = {
    targetPlan: '',
    targetProduct: '',
    discountAmount: 0,
    discountPercentage: 0,
    newPrice: 0,
    oldPrice: 0,
  }

  const promoCodeItem = findByCode(promoCode, promoCodes)
  if (!promoCodeItem) return discount

  discount.targetPlan = promoCodeItem.metadata.target
  discount.targetProduct = promoCodeItem.coupon.applies_to.products[0]
  discount.discountPercentage = promoCodeItem.coupon.percent_off ?? 0

  const basePriceInfo = getDetails(discount.targetProduct, currency, availablePlans)
  const basePrice = basePriceInfo.price.unit_amount / 100
  discount.oldPrice = basePrice

  // Calculate discount amount based on percentage from coupon
  discount.discountAmount = (basePrice * discount.discountPercentage) / 100
  discount.newPrice = limitFloatToTwoDecimals(basePrice - discount.discountAmount)

  return discount
}

export {
  validatePromoCode,
  couponIdFromPromoCode,
  normalizeCode,
  getDescription,
  promoAppliesToProduct,
  getPromoFromCode,
  getPriceAfterDiscount,
  buildDiscountObject,
  appliesToPlan,
}
