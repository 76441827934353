import { Price } from 'types'
import { SubscriptionOptionType } from 'types'

export const buildSubscriptionFromPrice = (price: Price): SubscriptionOptionType => {
  console.log('price', price)
  let interval: 'monthly' | 'yearly' | 'quarterly' = 'monthly'

  if (price.recurring?.interval === 'year') {
    interval = 'yearly'
  } else if (price.recurring?.interval === 'month' && price.recurring?.interval_count == 3) {
    interval = 'quarterly'
  }

  let total: string | number = price.unit_amount / 100
  if (interval === 'yearly') {
    // truncate for 2 decimal places eg. 14.99 instead of 14.99999 after monthly division.
    total = Math.floor((total / 12) * 100) / 100
  }

  if (interval === 'quarterly') {
    // truncate for 2 decimal places eg. 14.99 instead of 14.99999 after monthly division.
    total = Math.floor((total / 3) * 100) / 100
  }
  const period = interval.charAt(0).toUpperCase() + interval.slice(1)
  return {
    id: price.id,
    type: interval,
    period: period,
    currency: price.currency,
    price: price,
    total: total,
    basePrice: price.unit_amount / 100,
  }
}

export const buildSubscriptionsFromPrices = (prices?: Price[]): SubscriptionOptionType[] => {
  if (!prices) {
    return []
  }
  console.log('prices', prices)
  const subscriptions: SubscriptionOptionType[] = prices
    .filter((item) => item.active)
    .sort((a, b) => a.unit_amount - b.unit_amount)
    .map((item) => {
      return buildSubscriptionFromPrice(item)
    })

  return subscriptions
}
