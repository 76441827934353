import { useQuery } from 'react-query'
import getCountryCode from '../services/api/country' // Adjust path if needed
import { get } from '../services/api/helpers'

const useCountry = () => {
  return useQuery('country', getCountryCode, {
    staleTime: Infinity,
  })
}

export { useCountry }
