import React from 'react'
import { Button } from 'shared/components'
import styles from './ChallengeOverview.module.scss'

const ComingSoonButton = () => {
  const text = `Coming Soon!`
  return (
    <Button
      containerStyle={`${styles.selectPlanBtn} bg-coral mb-4 lg:max-w-lg w-full md:w-[70%] mx-auto disabled:bg-gray-600`}
      onClick={() => {
        return null
      }}
      label={text}
      disabled
    />
  )
}

export { ComingSoonButton }
