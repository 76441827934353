import React from 'react'
import { SignUpContextProvider } from '../../../features/SignUp/SignUpContext'
import { Subscription } from '../Subscription'

export const SubscriptionWrapper: React.FC = () => {
  return (
    <SignUpContextProvider>
      <Subscription />
    </SignUpContextProvider>
  )
}
