import React from 'react'
import { Reservation } from './Reservation'
import { useReservations } from '../hooks/useReservations'

const ReservationsSummary = () => {
  const { reservation, reservationLoading, reservationError } = useReservations()

  const notActive = reservation?.status === 'DISMISSED' || reservation?.status === 'CANCELLED' || reservation?.status === 'STARTED'

  // null if no reservations
  if (reservationError || reservationLoading || reservation === null || notActive) {
    return null
  }

  return (
    <div className=" ">
      <div className="relative ">
        <div className="overflow-visible  ">
          <div className="bg-teal mb-2 h-10 w-full rounded-xl text-white text-center leading-10 uppercase  text-2xl font-SfProDisplayBold font-bold">Scheduled</div>

          {/* Loading State */}
          {reservationLoading && <p className="text-gray-500">Loading...</p>}

          {/* Reservation Display */}
          {!reservationLoading && !reservationError && reservation !== null && reservation !== undefined && (
            <div className="border-t border-gray-200 pt-4">
              <Reservation data={reservation} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { ReservationsSummary }
