import React, { useState, useMemo } from 'react'

import { Button, Form } from 'antd'
import styles from '../SignUp.module.scss'
import { PaymentForm } from './PaymentForm'
import FormWrapper from './FormWrapper'
import { useSignUpContext } from '../SignUpContext'
import { Elements } from '@stripe/react-stripe-js'
import { stripePromise } from 'shared/functions/stripe'
import { usePromoCodes } from '../../../hooks/usePromoCodes'
import { useProducts } from '../../../hooks/useProducts'
import { PromoCodeUtils } from 'shared/functions'
import { useCountry } from 'hooks/useCountry'

const PaymentDetails: React.FC<{ name: string }> = ({ name }) => {
  const { goToPrevAccessForm, isStaging, selectedOption, selectedPrice, promoCode, prices } = useSignUpContext()
  const { data: country } = useCountry()

  const { data: promoCodes, isLoading: promoCodesLoading } = usePromoCodes()
  const { data: products, isLoading: productsLoading } = useProducts()

  const displayedCurrency = country?.countryCode === 'CA' ? 'CAD' : 'USD'

  const promo = useMemo(() => {
    if (promoCode && promoCodes) return PromoCodeUtils.getPromoFromCode(promoCode, promoCodes)
  }, [promoCode, promoCodes])

  const selectedProduct = useMemo(() => {
    if (selectedPrice && products) return products.find((product) => product.id === selectedPrice.product.id)
    return null
  }, [selectedPrice, products])

  const promoMatchesProductSelection = useMemo(() => {
    if (promo && selectedProduct) return PromoCodeUtils.promoAppliesToProduct(promo, selectedProduct)
  }, [promo, selectedProduct])

  const getPromoDescription = () => {
    let description = ''

    if (promo && selectedOption && prices && promoMatchesProductSelection) {
      description = PromoCodeUtils.getDescription(promoCode, promoCodes)
    }
    console.log(description)

    return description
  }

  const finalPrice = () => {
    if (selectedPrice && selectedProduct && promo && promoMatchesProductSelection) {
      const price = PromoCodeUtils.getPriceAfterDiscount(selectedPrice, selectedProduct, promo)
      return price / 100.0
    }
    const priceWithoutDiscount = selectedOption!.basePrice
    return parseFloat(priceWithoutDiscount.toString())
  }

  if (!prices || !selectedOption || !selectedPrice || !selectedProduct) {
    console.log('*** prices', prices)
    console.log('*** selectedOption', selectedOption)
    console.log('*** selectedPrice', selectedPrice)
    console.log('*** selectedProduct', selectedProduct)
    return <div>Loading...</div>
  }

  const renderPromoText = () => {
    if (promo && promoMatchesProductSelection) {
      return (
        <div className="text-green-500">
          <span className="font-bold pr-2">Promo Applied: </span>
          {getPromoDescription()}
        </div>
      )
    }
    return null
  }
  return (
    <FormWrapper onPressPrev={goToPrevAccessForm} step={3} title="FINISH UP!">
      <p className={styles.accessFormSection__subtitle}>Add your billing information</p>
      {isStaging && (
        <p className={styles.accessFormSection__subtitle}>
          TEST MODE: use a test card from the following{' '}
          <a target="_blank" rel="noreferrer" href="https://www.paypalobjects.com/en_AU/vhelp/paypalmanager_help/credit_card_numbers.htm">
            list
          </a>{' '}
          and any valid expiration, cvv and zipcode
        </p>
      )}
      <div className={styles.accessFormSection__form}>
        <Elements stripe={stripePromise}>
          <PaymentForm />
        </Elements>
      </div>
      <div className="flex flex-col mt-8">
        {renderPromoText()}
        <div className="total">
          <p className={styles.paymentDetails__title}>Total</p>
          <p className={`${styles.paymentDetails__price} ${styles.paymentDetails__price_total}`}>
            3 Day Free Trial then ${finalPrice()} {displayedCurrency} + tax
          </p>
        </div>
      </div>
    </FormWrapper>
  )
}

export default PaymentDetails
