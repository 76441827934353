import { get, post, patch, del } from './helpers' // Assuming these are your helper methods for HTTP requests
import { Reservation } from '../../types'
import { ApiPatchOptions, CreateReservationData, UpdateReservationData } from './types'
import moment from 'moment'

// Service Functions

const createReservation = async (data: CreateReservationData): Promise<Reservation> => {
  const res = await post<Reservation>('/v1/reservations', data)
  return res
}

const getReservationById = async (reservationId: string): Promise<Reservation> => {
  const res = await get<Reservation>(`/v1/reservations/${reservationId}`)
  return res
}

const updateReservation = async (reservationId: string, data: ApiPatchOptions): Promise<Reservation> => {
  const res = await patch<Reservation>(`/v1/reservations/${reservationId}`, data)
  return res
}

const cancelReservation = async (reservationId: string): Promise<Reservation> => {
  const res = await del<Reservation>(`/v1/reservations/${reservationId}`)
  return res
}

const getAllReservations = async (): Promise<Reservation> => {
  const res = await get<Reservation>('/v1/reservations')
  return res
}

const actionReservation = async (reservationId: string, isDownloadWeek: boolean): Promise<Reservation> => {
  const startOfDay = moment().startOf('day').toISOString()
  const data = {
    isDownloadWeek,
    startDate: startOfDay,
  }

  const res = await post<Reservation>(`/v1/reservations/${reservationId}/action`, data)
  return res
}

export { createReservation, getReservationById, updateReservation, cancelReservation, getAllReservations, actionReservation }

// export const userHasReservation = (reservations: Reservation[]): boolean => {
//   const reservation = reservations.find((reservation) => reservation.status === 'pending')
//   return reservation !== undefined
// }

// export const upcomingReservation = (reservations: Reservation[]): Reservation | undefined => {
//   const reservation = reservations.find((reservation) => reservation.status === 'pending')
//   return reservation
// }
