import { ActiveChallenge } from 'types'
import { get, post } from './helpers'
import {
  AdvanceWeekResponse,
  ApiPostOptions,
  CanSkipToNextWeekResponse,
  ChallengeType,
  completeChallengeResponse,
  CreateUserChallengeData,
  CreateUserChallengeMutationOptions,
  CreateUserChallengeResponse,
  UserChallengeWithRelatedData,
} from './types'
import { message } from 'antd'
import { User } from '@sentry/react'

const pause = async (userChallengeId: string): Promise<boolean> => {
  let response: string | { status: number; data: unknown }
  try {
    response = await post(`/userChallenges/${userChallengeId}/pause`, undefined)
    console.log({ response })
  } catch (error) {
    console.error('Error pausing user challenge:', error)
    return false
  }

  if (response === 'OK') {
    return true
  } else {
    return false
  }
}

const unpause = async (userChallengeId: string): Promise<boolean> => {
  let response: string | { status: number; data: unknown }
  try {
    response = await post(`/userChallenges/${userChallengeId}/unpause`, undefined)
    console.log({ response })
  } catch (error) {
    console.error('Error unpausing user challenge:', error)
    return false
  }

  if (response === 'OK') {
    return true
  } else {
    return false
  }
}

const isPaused = async (userChallengeId: string): Promise<boolean> => {
  let response: boolean
  try {
    response = await get(`/userChallenges/${userChallengeId}/paused`)
    console.log({ response })
    return response === true ? true : false
  } catch (error) {
    console.error('Error checking if user challenge is paused:', error)
    return false
  }
}

const getAllCompletedUserChallengesCount = async (): Promise<{ [key: string]: number } | null> => {
  try {
    const response = (await get(`userChallenges/allCompletedCounts`)) as { [key: string]: number }
    return response
  } catch (error) {
    console.error('Error getting all completed user challenges count:', error)
    return null
  }
}

const currentWeek = async (userChallengeId: string): Promise<number> => {
  try {
    const response = (await get(`userChallenges/${userChallengeId}/challengeWeek`)) as number
    console.log('currentWeek response:', response)
    return response
  } catch (error) {
    console.error('Error getting current week:', error)
    return 0
  }
}

const getCurrentUserChallenge = async (): Promise<UserChallengeWithRelatedData | null> => {
  try {
    const response = (await get(`userChallenges/current`)) as UserChallengeWithRelatedData
    // Assuming the response is a UserChallenge object
    return response
  } catch (error) {
    console.error('Error getting current user challenge:', error)
    return null
  }
}

const canAdvanceWeek = async (userChallengeId: string): Promise<CanSkipToNextWeekResponse> => {
  try {
    const response = await get(`userChallenges/${userChallengeId}/canAdvanceWeek?verbose=true`)
    if (typeof response === 'object' && response !== null) {
      if ('isEligible' in response && 'isFlex' in response && 'isPermittedChallenge' in response && 'isNotLastWeek' in response && 'completedAllWorkouts' in response) {
        return response as CanSkipToNextWeekResponse
      }
      console.error('Invalid response for canAdvanceWeek:', response)
      return {
        isEligible: false,
        isFlex: false,
        isPermittedChallenge: false,
        isNotLastWeek: false,
        completedAllWorkouts: false,
      }
    } else {
      console.error('Invalid response for canAdvanceWeek:', response)
      return {
        isEligible: false,
        isFlex: false,
        isPermittedChallenge: false,
        isNotLastWeek: false,
        completedAllWorkouts: false,
      }
    }
  } catch (error) {
    console.error('Error checking if can advance week:', error)
    return {
      isEligible: false,
      isFlex: false,
      isPermittedChallenge: false,
      isNotLastWeek: false,
      completedAllWorkouts: false,
    }
  }
}

const advanceWeek = async (userChallengeId: string) => {
  try {
    const response = (await post(`userChallenges/${userChallengeId}/advanceWeek`, undefined)) as AdvanceWeekResponse
    console.log('advanceWeek response :>> ', { response })
    // sadly then helper function doesn't give the status code so let's duck type it
    const successful = response.message === 'Week advanced successfully.'
    if (successful) {
      return true
    } else {
      console.error('Invalid response for advanceWeek:', response)
      return false
    }
  } catch (error) {
    console.error('Error advancing week:', error)
    return false
  }
}

const finishChallenge = async (userChallengeId: string): Promise<completeChallengeResponse> => {
  try {
    const response = await post(`userChallenges/${userChallengeId}/finish`, undefined)
    if (typeof response === 'object' && response !== null) {
      if ('message' in response) {
        return response as completeChallengeResponse
      }
      console.error('Invalid response for completeChallenge:', response)
      return {
        message: 'Invalid response',
      }
    } else {
      console.error('Invalid response for completeChallenge:', response)
      return {
        message: 'Invalid response',
      }
    }
  } catch (error) {
    console.error('Error completing challenge:', error)
    return {
      message: 'Error completing challenge',
    }
  }
}

const cancel = async (userChallengeId: string): Promise<any> => {
  try {
    const response = await post(`userChallenges/${userChallengeId}/cancel`, undefined)
    if (typeof response === 'object' && response !== null) {
      if ('message' in response) {
        return response
      }
      console.error('Invalid response for completeChallenge:', response)
      return {
        message: 'Invalid response',
      }
    }
  } catch (error) {
    console.error('Error canceling challenge:', error)
    return {
      message: 'Error canceling challenge',
    }
  }
}

const create = async ({ challengeType, userId, challengeId, startDate, withMusic, weeklyCommitment }: CreateUserChallengeMutationOptions): Promise<CreateUserChallengeResponse> => {
  const endpoint = challengeType === ChallengeType.Flex ? '/user/challenges/flex' : '/user/challenges'

  const payload: CreateUserChallengeData =
    challengeType === ChallengeType.Flex
      ? {
          user_id: userId,
          challenge_id: challengeId,
          start_date: startDate,
          is_active: true,
          workout_commitment: weeklyCommitment,
          with_music: withMusic,
        }
      : {
          user_id: userId,
          challenge_id: challengeId,
          start_date: startDate,
          with_music: withMusic,
        }

  const response = post<CreateUserChallengeResponse>(endpoint, payload)

  return response
}

export {
  pause,
  unpause,
  isPaused,
  getAllCompletedUserChallengesCount,
  currentWeek,
  // createUserChallenge,
  getCurrentUserChallenge,
  canAdvanceWeek,
  advanceWeek,
  finishChallenge,
  create,
  cancel,
}
