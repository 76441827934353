import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
// import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

type ConfirmationModalProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  question: string
  onConfirm: () => void
  children?: React.ReactNode
  showWarning?: boolean
  warningText?: string
  onRefute?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {}

const ConfirmationModal = ({ isOpen, setIsOpen, question, onConfirm, children, showWarning = false, warningText, onRefute = noOp }: ConfirmationModalProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg shadow-xl p-6 text-center w-full max-w-md">
                {children}
                <h3 className="text-lg font-medium text-gray-900">{question}</h3>
                <div className="mt-4 flex justify-center gap-4">
                  <button
                    className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
                    onClick={() => {
                      onRefute()
                      setIsOpen(false)
                    }}
                  >
                    No
                  </button>
                  <button
                    className="px-4 py-2 bg-coral text-white rounded-md hover:bg-coral-dark"
                    onClick={() => {
                      onConfirm()
                      setIsOpen(false)
                    }}
                  >
                    Yes
                  </button>
                </div>
                {showWarning && (
                  <div className="mt-4 flex items-center gap-4 rounded-lg bg-gray-100 p-4 border border-gray-300 shadow-sm">
                    <ExclamationTriangleIcon className="w-6 h-6 stroke-yellow-500" strokeWidth={2} />
                    <span className="italic text-red-600">{warningText}</span>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export { ConfirmationModal }
