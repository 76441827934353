import React from 'react'
import styles from '../Subscription.module.scss'
import { loadStripe, PaymentMethod } from '@stripe/stripe-js'
import { CardForm } from './CardForm'
import { Elements } from '@stripe/react-stripe-js'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { AuthState } from '../../../types/index'
import { SubscriptionOptionType, CurrentViewOptions } from 'types'
import { Box } from 'shared/components'

interface PaymentMethodFormProps {
  changeView: (view: CurrentViewOptions, reason: string) => void
  selectedOption: any // Replace 'any' with the proper type for your selected option
  authState: AuthState
  activeSubscription: SubscriptionOptionType | undefined
  currentView: CurrentViewOptions
  onPaymentSuccess: (paymentMethod: PaymentMethod, name: string, postalCode?: string, country?: string) => void
  updateCustomerLoading: boolean
  creatingSubscription: boolean
  selectedCouponId?: string
}

/**
 *  PaymentMethodForm Component
 */
const PaymentMethodForm = ({
  changeView,
  authState,
  activeSubscription,
  currentView,
  selectedOption,
  onPaymentSuccess,
  updateCustomerLoading,
  creatingSubscription,
  selectedCouponId,
}: PaymentMethodFormProps) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN || '')

  const showPromos = true
  return (
    <Elements stripe={stripePromise}>
      <button className={styles.topPadding} onClick={() => changeView(activeSubscription ? null : 'updatePlan', 'clicked in form')}>
        <ArrowLeft />
      </button>

      {currentView === 'updatePayment' && (
        <CardForm selectedOption={null} onCreateCard={onPaymentSuccess} user={authState.user} loading={updateCustomerLoading} showPromo={showPromos} currentView={currentView} />
      )}
      {currentView === 'paymentForm' && (
        <CardForm selectedOption={null} onCreateCard={onPaymentSuccess} user={authState.user} loading={updateCustomerLoading} showPromo={showPromos} currentView={currentView} />
      )}
    </Elements>
  )
}

export { PaymentMethodForm }
