import { useQuery } from 'react-query'
import { getStripeSubscription } from '../services/api/subscriptions'
import { UserSubscription } from '../types'

export const useStripeSubscription = (userId: string) => {
  return useQuery<UserSubscription>(['stripeSubscription', userId], () => getStripeSubscription(userId), {
    enabled: !!userId,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
  })
}
