import React from 'react'
import { FeaturedChallenge } from './FeaturedChallenge'
import { getFeaturedChallenges } from 'services/api/settings'
import { useQuery } from 'react-query'

const FeaturedChallengeList = () => {
  const {
    data: featuredChallenges,
    isLoading: featuredChallengesLoading,
    error,
  } = useQuery('featuredChallenges', () => getFeaturedChallenges(), {
    staleTime: 86400000, // 1 day
    refetchOnWindowFocus: true,
    retry: false,
  })

  if (!featuredChallenges || featuredChallenges.length !== 2) return null

  return (
    <div className=" mt-4">
      <div className="relative ">
        <div className="overflow-visible  ">
          <div className="bg-teal mb-2 h-10 w-full rounded-xl text-white text-center leading-10 uppercase  text-2xl font-SfProDisplayBold font-bold">Featured</div>
          {featuredChallengesLoading && <div></div>}
          {featuredChallenges.length >= 0 && (
            <>
              <FeaturedChallenge
                key={featuredChallenges[0].title}
                name={featuredChallenges[0].title}
                image={featuredChallenges[0].image}
                clickTarget={`/challenges/${featuredChallenges[0].id}/overview`}
              />
              <FeaturedChallenge
                key={featuredChallenges[1].title}
                name={featuredChallenges[1].title}
                image={featuredChallenges[1].image}
                clickTarget={`/challenges/${featuredChallenges[1].id}/overview`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { FeaturedChallengeList }
