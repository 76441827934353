import React from 'react'
import { Button } from 'shared/components'
import styles from './ChallengeOverview.module.scss'
import { useUserChallenges } from 'hooks/useUserChallenges'

type CancelChallengeButtonProps = {
  currentUserChallengeId: string
  redirect?: boolean
}

const CancelChallengeButton = ({ currentUserChallengeId, redirect = true }: CancelChallengeButtonProps): JSX.Element => {
  const UserChallenges = useUserChallenges()

  const onClickCancelPlan = async () => {
    const id = currentUserChallengeId
    await UserChallenges.cancel(id, redirect)
  }

  return (
    <Button
      containerStyle={`${styles.selectPlanBtn} bg-coral mb-4 lg:max-w-lg  w-full  md:w-[70%] mx-auto`}
      onClick={onClickCancelPlan}
      label="Cancel Current Challenge"
      loading={UserChallenges.cancelLoading}
    />
  )
}

export { CancelChallengeButton }
