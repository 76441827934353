import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useContext, useMemo, useState } from 'react'
import { NotyfContext } from 'context/NotyfContext'
import { AuthStateContext } from 'context/AuthStateContext'
import { useUserChallenges } from 'hooks/useUserChallenges'
import { useReservations } from 'hooks/useReservations'
import dayjs from 'shared/functions/dayjs'
import { Challenge } from 'types'
import { ChallengeType, CreateUserChallengeMutationOptions } from 'services/api/types'
import { ReactComponent as PhilIllustration } from 'assets/images/ondemand-filter-header.svg'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

type ScheduleFlexUserChallengeModalProps = {
  challenge: Challenge
  setIsOpen: (open: boolean) => void
  isInAChallenge?: boolean
}

/**
 * @typedef {Object} ScheduleFlexUserChallengeModalProps
 * @property {Challenge} challenge - The challenge data.
 * @property {(open: boolean) => void} setIsOpen - Function to control modal visibility.
 */

/**
 * Modal component for scheduling a Flex User Challenge.
 * @param {ScheduleFlexUserChallengeModalProps} props
 * @returns {JSX.Element}
 */
export const ScheduleFlexUserChallengeModal = ({ challenge, setIsOpen, isInAChallenge = false }: ScheduleFlexUserChallengeModalProps) => {
  const notyf = useContext(NotyfContext)
  const { state: authState } = useContext(AuthStateContext)
  const { create, createChallengeLoading } = useUserChallenges()
  const { createReservation, creatingReservation } = useReservations()
  const [withMusic, setWithMusic] = useState(true)
  const [commitment, setCommitment] = useState(challenge?.defaultCommitment || 3)
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [showDownloadWeek, setShowDownloadWeek] = useState(false)
  const [includeDownloadWeek, setIncludeDownloadWeek] = useState(false)
  const [dateRequiresWarning, setDateRequiresWarning] = useState(false)

  const userId = authState.user?.id
  const isBusy = createChallengeLoading || creatingReservation

  /**
   * Builds the options for creating a Flex challenge.
   * @returns {CreateUserChallengeMutationOptions} The challenge options object.
   */
  const buildOptions = (): CreateUserChallengeMutationOptions => {
    const isoStartDate = dayjs(startDate).startOf('day').toISOString()
    return {
      challengeType: ChallengeType.Flex,
      userId: userId || '',
      challengeId: challenge.id,
      startDate: isoStartDate,
      withMusic,
      weeklyCommitment: commitment,
    }
  }

  /**
   * Handles changes to the start date input.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value
    const startOfSelectedDay = dayjs(selectedDate).startOf('day')
    const daysFromNow = startOfSelectedDay.diff(dayjs().startOf('day'), 'days')
    setStartDate(selectedDate)
    setShowDownloadWeek(daysFromNow >= 1 && daysFromNow <= 7)
    console.log('daysFromNow', daysFromNow)
    console.log('showDownloadWeek', showDownloadWeek)

    // in a challenge they want a downloadweek and they choose a date less than 7 days from now
    const creatingAUc = (includeDownloadWeek && daysFromNow < 7) || daysFromNow < 1
    console.log('creatingAUc', creatingAUc)

    setDateRequiresWarning(isInAChallenge && creatingAUc)
    console.log('dateRequiresWarning', dateRequiresWarning)
  }

  /**
   * Starts the challenge immediately.
   * @returns {Promise<void>}
   */
  const handleStartNow = async () => {
    console.log('starting flex challenge')
    if (!userId) {
      notyf.error('Please login to start a challenge')
      return
    }
    try {
      const options = buildOptions()
      const redirect = true
      const response = await create({ ...options, redirect })
      console.log('response', response)
      if (response) {
        setIsOpen(false)
      }
    } catch (err) {
      console.error('Error creating user challenge:', err)
      notyf.error('Challenge creation failed')
    }
  }

  /**
   * Creates a reservation for a scheduled challenge start.
   * @returns {Promise<void>}
   */
  const scheduleReservation = async () => {
    const options = buildOptions()
    const preferences = {
      withMusic: options.withMusic,
      challengeId: options.challengeId,
      startDate: options.startDate,
      weeklyCommitment: options.weeklyCommitment,
    }
    try {
      const redirect = true
      const newRes = await createReservation({ ...options, preferences, redirect })
      if (newRes) {
        notyf.success('Reservation created')
        setIsOpen(false)
      }
    } catch (error) {
      console.error('Error creating reservation:', error)
      notyf.error('Failed to create reservation')
    }
  }

  /**
   * Confirms the scheduled challenge start date.
   * @returns {Promise<void>}
   */
  const handleStartChallenge = async () => {
    if ((daysFromNow <= 7 && includeDownloadWeek) || daysFromNow < 1) {
      await handleStartNow()
    } else {
      await scheduleReservation()
    }
  }

  // For generating slider tickmarks
  /**
   * Generates options for the workout commitment slider.
   * @returns {JSX.Element[]} The list of option elements.
   */
  const maxCommitment = challenge.workoutsPerWeek || 5
  const renderOptions = useMemo(() => {
    const labelOptions = []
    const stepValue = 100 / (maxCommitment - 1)
    labelOptions.push(<option key="firstOption" value="0" label="1" />)
    for (let i = 2; i < maxCommitment; i++) {
      labelOptions.push(<option key={i} value={stepValue * (i - 1)} label={i.toString()} />)
    }
    labelOptions.push(<option key={maxCommitment} value="100" label={maxCommitment.toString()} />)
    return labelOptions
  }, [maxCommitment])

  const determineButtonText = () => {
    const daysFromNow = dayjs(startDate).startOf('day').diff(dayjs().startOf('day'), 'days')
    const formatedDate = dayjs(startDate).format('MMM D')
    switch (true) {
      case isBusy:
        return 'Working...'
      case dateRequiresWarning:
        return `Cancel and start on ${formatedDate}`
      case daysFromNow < 1 && isInAChallenge:
        return 'Cancel Start Now'
      case daysFromNow < 1:
        return 'Start Now'
      case includeDownloadWeek && showDownloadWeek:
        return `Cancel and Start on ${formatedDate}`
      default:
        return `Start on ${formatedDate}`
    }
  }

  const today = dayjs().format('YYYY-MM-DD')
  const releaseDate = dayjs(challenge.releaseDate).format('YYYY-MM-DD')
  const earliestDateForRes = today > releaseDate ? today : releaseDate
  const daysFromNow = dayjs(startDate).startOf('day').diff(dayjs().startOf('day'), 'days')
  const creatingAUc = (includeDownloadWeek && daysFromNow < 7) || daysFromNow < 1
  const showWarning = isInAChallenge && creatingAUc

  return (
    <Transition.Root show as={Fragment}>
      <Dialog as="div" className="relative z-200" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-99 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-200 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="lg:ml-[300px] relative transform overflow-hidden rounded-lg transition-transform bg-white px-1 pt-5 pb-4 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-4">
                <div className="text-center">
                  <h2 className="text-2xl font-extrabold">{challenge.title}</h2>
                  {/* <h4 className="text-xl font-bold mt-1">Select Your Number Of Workouts!</h4> */}
                  {/* <p>Adjust according to your goals and schedule!</p> */}
                  <p>Your goals, Your schedule!</p>

                  <div className="my-4 grid grid-cols-4">
                    <PhilIllustration />
                    <div className="col-span-3 text-left pl-2">
                      <h3 className="font-bold">Recommendation:</h3>
                      <p className="text-sm mt-1">{challenge.recommendation || 'I recommend 2 - 3 workouts per week for best results!'}</p>
                    </div>
                  </div>

                  <div className="bg-slate-50 rounded-md p-3 mb-2">
                    <h3 className="text-xl font-bold">Workouts Per Week</h3>
                    <input
                      type="range"
                      className="m-0 mb-1 mt-2 w-full h-2 bg-gray-200 rounded-lg cursor-pointer"
                      min="1"
                      max={maxCommitment}
                      step="1"
                      value={commitment}
                      onChange={(e) => setCommitment(parseInt(e.target.value))}
                    />
                    <datalist className="flex justify-between" id="tickmarks">
                      {renderOptions}
                    </datalist>
                  </div>
                </div>

                <div className="bg-slate-50 rounded-md p-3 mb-2 text-center">
                  {/* <label className="block font-semibold mb-2">Would you like to workout with Music?</label> */}
                  <div className="grid grid-cols-2 gap-2">
                    <button
                      type="button"
                      className={`h-12 transition-all justify-center rounded-lg font-bold text-md ${
                        withMusic ? 'bg-coral text-white hover:bg-coral-dark ring-2 ring-slate-500' : 'bg-slate-100 text-slate-800 border border-slate-500'
                      }`}
                      onClick={() => setWithMusic(true)}
                    >
                      Music
                    </button>
                    <button
                      type="button"
                      className={`h-12 transition-all justify-center rounded-lg font-bold text-md ${
                        !withMusic ? 'bg-coral text-white hover:bg-coral-dark ring-2 ring-slate-500' : 'bg-slate-100 text-slate-800 border border-slate-500'
                      }`}
                      onClick={() => setWithMusic(false)}
                    >
                      No Music
                    </button>
                  </div>
                </div>

                <div className="bg-slate-50 rounded-md p-4 my-4 text-center">
                  <label className="block font-semibold mb-4 text-lg">When would you like to start?</label>

                  <div className="w-full text-left">
                    {/* <label className="block mb-2 text-gray-700 font-medium">Schedule Your Start Date:</label> */}
                    <input
                      type="date"
                      className="w-full p-2 border rounded-md focus:ring-coral-dark focus:border-coral"
                      value={startDate}
                      onClick={(e) => {
                        const input = e.target as HTMLInputElement
                        if (document.activeElement === input) {
                          input.showPicker?.()
                        }
                      }}
                      onChange={handleDateChange}
                      min={earliestDateForRes}
                    />
                  </div>

                  {showDownloadWeek && (
                    <div className="mt-4 transition-opacity duration-300 ease-in-out">
                      <label className="flex items-center gap-4">
                        <span className="text-gray-700 font-lg">Prep with download week workouts?</span>
                        <div className="relative">
                          <input type="checkbox" checked={includeDownloadWeek} onChange={(e) => setIncludeDownloadWeek(e.target.checked)} className="sr-only peer" />
                          <div className="w-11 h-6 bg-gray-200 rounded-full peer-focus:ring-2 peer-focus:ring-coral-dark peer-checked:bg-coral-dark" />
                          <div className="absolute left-0 top-0 w-6 h-6 bg-white rounded-full shadow-md transform peer-checked:translate-x-5 transition-transform" />
                        </div>
                      </label>
                    </div>
                  )}

                  {showWarning && (
                    <div className="mt-2 flex items-center gap-4 rounded-lg bg-gray-100 p-4 border border-gray-300 shadow-sm">
                      <ExclamationTriangleIcon className="w-6 h-6 stroke-yellow-500" strokeWidth={2} />
                      <span className="italic text-red-600">{'This will end your current challenge'}</span>
                    </div>
                  )}
                  <button className="w-full h-12 bg-coral text-white rounded-lg font-bold mt-2 hover:bg-coral-dark" onClick={handleStartChallenge} disabled={isBusy}>
                    {determineButtonText()}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
