// useUserChallenges.ts
import { useMutation, useQueryClient } from 'react-query'
import * as UserChallengesApi from 'services/api/userChallenges'
import { CreateUserChallengeMutationOptions } from 'services/api/types'
import { NotyfContext } from 'context/NotyfContext'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

export const useUserChallenges = () => {
  const queryClient = useQueryClient()
  const notyf = useContext(NotyfContext)
  const history = useHistory()

  // Create Challenge
  const createChallengeMutation = useMutation((opts: CreateUserChallengeMutationOptions) => UserChallengesApi.create(opts), {
    onSuccess: (data, variables) => {
      // Invalidate queries so we refresh relevant data
      queryClient.invalidateQueries('activeChallenge')
      queryClient.invalidateQueries('todayContent')
      queryClient.invalidateQueries('plannerWorkouts')
      queryClient.invalidateQueries('currentUserChallenge')
      notyf.success('Challenge Started!')
      if (variables?.redirect) {
        history.push('/today')
      }
    },
  })

  const create = (opts: CreateUserChallengeMutationOptions) => {
    return createChallengeMutation.mutateAsync(opts)
  }

  //   // Pause Challenge
  //   const pauseMutation = useMutation((challengeId: string) => UserChallengesApi.pause(challengeId), {
  //     onSuccess: () => {
  //       // Invalidate relevant queries
  //       queryClient.invalidateQueries('activeChallenge')
  //       queryClient.invalidateQueries('currentUserChallenge')
  //     },
  //   })

  //   const pauseChallenge = (challengeId: string) => {
  //     return pauseMutation.mutateAsync(challengeId)
  //   }

  //   // Unpause Challenge
  //   const unpauseMutation = useMutation((challengeId: string) => UserChallengesApi.unpause(challengeId), {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries('activeChallenge')
  //       queryClient.invalidateQueries('currentUserChallenge')
  //     },
  //   })

  //   const unpauseChallenge = (challengeId: string) => {
  //     return unpauseMutation.mutateAsync(challengeId)
  //   }

  // Advance Week
  const advanceWeekMutation = useMutation((challengeId: string) => UserChallengesApi.advanceWeek(challengeId), {
    onSuccess: () => {
      queryClient.invalidateQueries('activeChallenge')
      queryClient.invalidateQueries('currentUserChallenge')
      notyf.success('Week advanced!')
    },
  })

  const advanceWeek = (challengeId: string) => {
    return advanceWeekMutation.mutateAsync(challengeId)
  }

  // Finish Challenge
  const finishChallengeMutation = useMutation((challengeId: string) => UserChallengesApi.finishChallenge(challengeId), {
    onSuccess: () => {
      queryClient.invalidateQueries('activeChallenge')
      queryClient.invalidateQueries('currentUserChallenge')
      queryClient.invalidateQueries('todayContent')
      notyf.success('Challenge completed!')
    },
  })

  const finish = (challengeId: string) => {
    return finishChallengeMutation.mutateAsync(challengeId)
  }

  const cancelChallengeMutation = useMutation(({ challengeId }: { challengeId: string; redirect?: boolean }) => UserChallengesApi.cancel(challengeId), {
    onSuccess: (data, variables) => {
      queryClient.setQueryData('activeChallenge', null)
      queryClient.invalidateQueries('currentUserChallenge')
      queryClient.invalidateQueries('todayContent')
      notyf.success('Challenge cancelled!')

      if (variables?.redirect) {
        history.push('/challenges')
      }
    },
  })

  const cancel = (challengeId: string, redirect = false) => {
    return cancelChallengeMutation.mutateAsync({ challengeId, redirect })
  }

  return {
    // Create
    create,
    createChallengeLoading: createChallengeMutation.isLoading,

    // // Pause
    // pauseChallenge,
    // pauseLoading: pauseMutation.isLoading,

    // // Unpause
    // unpauseChallenge,
    // unpauseLoading: unpauseMutation.isLoading,

    // Advance
    advanceWeek,
    advanceWeekLoading: advanceWeekMutation.isLoading,

    // Finish
    finish,
    finishLoading: finishChallengeMutation.isLoading,
    // Cancel
    cancel,
    cancelLoading: cancelChallengeMutation.isLoading,
  }
}
