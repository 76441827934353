import { Achievement, AchievementCriteria, ChallengeAchievementAssociation, CompletedWorkoutPrisma, UserAchievement, UserAchievementCriteria } from '../../types/index'
import { setFeaturedChallenge, setNonAdvanceableChallenges } from './settings'
import { actionReservation } from './reservations'
import { message } from 'antd'
import {
  ActiveChallenge,
  Challenge,
  QA_Record,
  Questionnaire,
  QuestionnaireAnswer,
  Recipe,
  RecipeCategory,
  RecommendedRecipes,
  Workout,
  WorkoutClass,
  WorkoutFavorites,
  WorkoutType,
  MealType,
  Question,
  QuestionPreference,
  TransformationPhoto,
  LightboxPhoto,
  TransformationPhotosByDate,
  CompletedWorkout,
  Price,
  UserSubscription,
  GenderOption,
  BMR_Option,
} from 'types'

// API

export type RuleSet = {
  id: string
}

export type AdminUserChallenge = {
  email: string
  gender: 'Male' | 'Female' | null
  id: string
  challengeId: string
  startDate: string
  endDate: string
  userId: string
  weeklyCommitment: number
  withMusic: boolean
  completed: boolean
  cancelled: boolean
}

export type AdminChallengeData = {
  userChallenges: AdminUserChallenge[]
}

export type RuleSetValidationOptions = {
  ruleSetString: string
  userId: string
  data?: any
}

export type ShowPromoCodeSettingOptions = {
  showPromoCodeContactUs: boolean
}

export type SetFeaturedChallengeOptions = {
  featuredChallenge: string
  featuredNumber: number
}

export interface CanSkipToNextWeekResponse {
  isEligible: boolean
  isFlex: boolean
  isPermittedChallenge: boolean
  isNotLastWeek: boolean
  completedAllWorkouts: boolean
}

export type SetNonAdvanceableChallengesOptions = {
  value: string[]
}

export type incrementPostData = {
  timestampIso: string
  isYesterday: boolean
  userTimeZone: string
}

export type CreateReservationData = {
  userId: string
  challengeId: string
  startDate: string
  preferences?: Record<string, any>
}

export type UpdateReservationData = {
  startDate?: string
  preferences?: Record<string, any>
}

export type actionReservationData = {
  isDownloadWeek: boolean
}
export type AdvanceWeekResponse = {
  message: string
  newCurrentWeek: number
  newEndDate: string
  newSkipHours: number
}

export type completeChallengeResponse = {
  message: string
}

export type CreateUserChallengeData = {
  user_id: string
  challenge_id: string
  start_date: string
  with_music: boolean
  workout_commitment?: number //flex only
  is_active?: boolean // legacy i don't think this is used
}

export type ApiPostOptions =
  | AdminUpdateUserChallengeOptions
  | CreateUserOptions
  | RemoveFromWorkoutFavoritesBody
  | AddToWorkoutFavoritesBody
  | QuestionnaireAnswersCreateOptions
  | CreateScheduledRecipeOptions
  | SaveScheduledRecipePlanOptions
  | CreateTransformationPhotoOptions
  | CreateCompletedWorkoutOptions
  | CreateCustomerOptions
  | CreateSubscriptionOptions
  | UpdateSubscriptionOptions
  | UpdateCustomerOptions
  | RuleSetValidationOptions
  | ShowPromoCodeSettingOptions
  | Achievement
  | AchievementCriteria
  | UserAchievement
  | UserAchievementCriteria
  | incrementPostData
  | SetFeaturedChallengeOptions
  | SetNonAdvanceableChallengesOptions
  | undefined
  | ChallengeAchievementAssociation[]
  | CreateReservationData
  | UpdateReservationData
  | actionReservationData
  | CreateUserChallengeData

export type ApiGetOptions = AdminChallengeData

export type ApiPatchOptions =
  | undefined
  | UpdateUserOptions
  | QuestionnaireAnswersUpdateOptions
  | UpdateUserChallengeOptions
  | Achievement
  | AchievementCriteria
  | UserAchievement
  | UserAchievementCriteria
  | UpdateUserNameOptions

//
// USERS
//

export type CreateUserOptions = {
  email: string
  first_name: string
  last_name?: string
}

export type UpdateUserNameOptions = {
  userId: string
  firstName: string
  lastName: string
}

export type UpdateUserOptions = {
  email?: string
  first_name?: string
  last_name?: string
  profile_picture?: string
  push_token?: string
  height_cm?: number
  weight_kg?: number
  unit_preference?: 'imperial' | 'metric'
  birthday?: string | null
  gender?: GenderOption | null
  streak?: number
  highest_streak?: number
}

export type AdminUpdateUserChallengeOptions = {
  challengeId: string
  startDate: string
  endDate: string
  commitment: number
  withMusic: boolean
  userId: string
  completed: boolean
  cancelled: boolean
}

export type UserResponse = {
  id: string
  email: string
  first_name: string
  last_name: string
  profile_picture: string
  push_token?: string
  height_cm?: number
  weight_kg?: number
  unit_preference?: 'imperial' | 'metric'
  chat_token?: string
  birthday?: string | null
  gender?: GenderOption | null
  bmr_range?: BMR_Option
  subscription: UserSubscription
  preferences: QuestionnaireAnswer[]
  created_at: string
  updated_at: string
  streak: number
  highest_streak: number
  timezone?: string
}

//
// QUESTIONNAIRE
//

export type QuestionnaireResponse = Questionnaire
export type QuestionnaireAnswersResponse = QuestionnaireAnswer
export type QuestionnaireAnswersCreateOptions = {
  questionnaire_title: string
  question_id: string
  option_id: string
  record?: QA_Record
  preference_type?: string
}
export type QuestionnaireAnswersUpdateOptions = {
  questionnaire_title?: string
  question_id?: string
  option_id: string
  record: QA_Record
  preference_type?: string
}
export type QuestionResponse = Question
export type QuestionPreferenceResponse = QuestionPreference[]
//
// WORKOUTS
//

export type WorkoutTypesResponse = WorkoutType[]
export type WorkoutClassesResponse = WorkoutClass[]
export type WorkoutResponse = Workout[]
export type WorkoutFavoritesResponse = WorkoutFavorites[]

export type AddToWorkoutFavoritesBody = {
  workout_id: string
}

export type RemoveFromWorkoutFavoritesBody = {
  workout_id: string
}

export type CompletedWorkoutsResponse = Workout[]
export type CreateCompletedWorkoutOptions = {
  workout_id: string
  completed_date: string
  challenge_workout_id?: string
  user_challenge_id?: string
  score?: number
}
export type CreateCompletedWorkoutResponse = CompletedWorkout

export type PlannerWorkoutsResponse = {
  challenge_workouts: Workout[]
  completed_workouts: Workout[]
}

//
// RECIPES
//

export type RecipeCategoriesResponse = RecipeCategory[]
export type RecipeResponse = Recipe[]
export type MealTypesResponse = MealType[]

//
// SCHEDULED RECIPES
//

export type GetScheduledRecipeResponse = {
  [key: string]: Recipe
}

export type CreateScheduledRecipeOptions = Array<{
  recipe_id: string
  scheduled_date: string
  meal_type: string
}>

export type SaveScheduledRecipePlanOptions = {
  scheduled_date: string
  recipes: Array<{
    recipe_id: string
    meal_type: string
  }>
}

export type SaveScheduledPlanResponse = {
  removed: Record<'id', string>[]
  saved: Record<'id', string>[]
}
export type DeleteResponse = {
  [key: string]: string
}

//
// User Challenges
//

export type ChallengesResponse = Challenge[]
export type ChallengeResponse = Challenge
export type ActiveChallengeResponse = ActiveChallenge
export type ActiveChallengeWorkoutResponse = Workout[]
export enum ChallengeType {
  Flex = 'flex',
  Legacy = 'legacy',
}

export type CreateUserChallengeMutationOptions = {
  challengeType: ChallengeType //'flex'||'legacy '
  userId: string
  challengeId: string
  startDate: string
  withMusic: boolean
  weeklyCommitment?: number
  redirect?: boolean
}

export type UpdateUserChallengeOptions = {
  challenge_id?: string
  cancelled?: boolean
  start_date?: string
  end_date?: string
}

export type CreateUserChallengeResponse = {
  message: string
}

export type UpdateChallengeVariables = {
  userChallengeId: string
  options: UpdateUserChallengeOptions
}

export type UpdateChallengeResponse = {
  id: string
  user_id: string
  challenge_id: string
  start_date: string
  end_date: string
  cancelled: boolean
}

//
// Recommendations
//
export type RecommendationsResponse = {
  workout?: Workout
  recipes?: RecommendedRecipes
}
export type DailyContentReponse = {
  workout?: Workout
  recipes?: RecommendedRecipes
  weeklyCommitment?: number
  weeklyWorkouts?: Workout[]
  expressWorkouts?: Workout[]
  optionalStretches?: Workout[]
  weekNumber?: number
}

//
// Transformation Photos
//

export type TransformationPhotosResponse = TransformationPhoto[]
export type TransformationPhotosFormattedResponse = {
  allPhotos: LightboxPhoto[]
  photosByDate: TransformationPhotosByDate
}
export type CreateTransformationPhotoOptions = {
  photo_url: string
}
export type CreateTransformationPhotoResponse = TransformationPhoto

//
// LEAN STATS
//
export type LeanScoresResponse = {
  last_score: number
  last_week_score: number
  all_time_score: number
}
export type LeanStreaksResponse = {
  best_consecutive: number
  last_week_consecutive: number
  current_consecutive: number
}

//
// SUBSCRIPTIONS
//

export type PricesResponse = { object: string; data: Price[]; has_more: boolean; url: 'string' }
export type CreateCustomerOptions = {
  name: string
  email: string
  metadata: Record<string, string>
  country?: string
  postal_code?: string
}
export type UpdateCustomerOptions = {
  customerId: string
  paymentMethodId: string
  promoCode?: string
}
export type UpdateCustomerResponse = {
  customer_id: string
}
export type CreateCustomerResponse = {
  customer_id: string
}

export type CreateSubscriptionOptions = {
  customerId: string
  priceId: string
  paymentMethodId: string
  metadata?: Record<string, string>
  coupon?: string
  promoCode?: string
}

export type CreateAndUpdateSubscriptionResponse = {
  subscription_id: string
  status: string
}

export type UpdateSubscriptionOptions = {
  subscriptionId: string
  priceId?: string
  paymentMethodId?: string
  metadata?: Record<string, string>
  promoCode?: string
}

//
// User Challenges
//
export type UserChallengeWithRelatedData = {
  id: string
  userId: string
  challengeId: string
  challenge?: Challenge
  startDate: Date
  endDate: Date
  cancelled: boolean | null
  createdAt: Date | null
  completed: boolean | null
  weeklyCommitment: number | null
  workoutTypeIds: string[]
  withMusic: boolean | null
  skipHours: number
  challengeWorkouts: ChallengeWorkout[]
  completedWorkouts: CompletedWorkoutPrisma[]
}

export type ChallengeWorkout = {
  id: string
  userId: string
  userChallengeId: string
  scheduledDate: Date | null
  workoutId: string
  challengeId: string | null
  week: number | null
}
