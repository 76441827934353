import { get } from './helpers'

const getCountryCode = async (): Promise<{ countryCode: string } | null> => {
  try {
    const response = await get<{ countryCode: string }>('/country') // Adjust the path if needed

    return { countryCode: response.countryCode }
  } catch (error) {
    console.error('Error fetching country code:', error)
    return null
  }
}

export default getCountryCode
