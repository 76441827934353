import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react'
import type { Challenge, Reservation as ReservationType } from '../types'
import { ChallengeContext } from 'context/ChallengeContext'
import { useReservations } from '../hooks/useReservations'
import { useHistory } from 'react-router-dom'
import { ConfirmationModal } from './modals/ConfirmationModal'
import dayjs from 'dayjs'
import { UserChallengeContext } from '../context'

type ReservationProps = {
  data: ReservationType
}

const Reservation: React.FC<ReservationProps> = ({ data }) => {
  const { cancelReservation, cancelingReservation, actionReservation, actingReservation, updateReservation } = useReservations()
  const { state: challengesContext } = useContext(ChallengeContext)
  const [displayedChallenge, setDisplayedChallenge] = useState<Challenge | null>(null)
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = React.useState(false)
  const [showStartConfirmationModal, setShowStartConfirmationModal] = React.useState(false)
  const [showReservationIsDueModal, setShowReservationIsDueModal] = React.useState(false)
  const [showReservationIsDueWithDownloadWeekModal, setShowReservationIsDueWithDownloadWeekModal] = React.useState(false)
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge } = userChallengeContextState

  const handleCancel = useCallback(() => {
    cancelReservation(data.id)
  }, [cancelReservation, data.id])

  const handleStart = useCallback(() => {
    actionReservation(data.id, false)
  }, [actionReservation, data.id])

  const handleDismissDownloadWeek = useCallback(() => {
    setShowReservationIsDueWithDownloadWeekModal(false)
    updateReservation(data.id, { status: 'PROMPT_START' })
  }, [updateReservation, data.id])

  const handleStartDownloadWeek = useCallback(() => {
    actionReservation(data.id, true)
  }, [actionReservation, data.id])

  const handleDismissReservation = useCallback(() => {
    updateReservation(data.id, { status: 'CANCELLED' })
    setShowReservationIsDueModal(false)
  }, [updateReservation, data.id])

  useEffect(() => {
    if (data.startDate && dayjs(data.startDate).isBefore(dayjs())) {
      console.log('Setting the reservation modal to pop')
      setShowReservationIsDueModal(true)
    } else {
      console.log('Setting the reservation modal to pop')
    }
  }, [data.startDate])

  useEffect(() => {
    if (!challengesContext?.array?.length) return
    const challenge = challengesContext.array.find((c) => c.id === data.challengeId)
    if (challenge) {
      setDisplayedChallenge(challenge)
    }
  }, [challengesContext])

  useEffect(() => {
    if (dayjs(data.startDate).diff(dayjs(), 'day') <= 7 && data.status === 'PENDING') {
      setShowReservationIsDueWithDownloadWeekModal(true)
    }
  }, [data.startDate, data.status])

  if (!displayedChallenge) return null

  const imageUrl = `${displayedChallenge.image}?w=555&h=312&q=90`
  const showWarning = !!activeChallenge
  const startNowButtonDisabled = !!(displayedChallenge && displayedChallenge.releaseDate && dayjs(displayedChallenge.releaseDate).isAfter(dayjs()))

  return (
    <div className="bg-white shadow-md rounded-lg p-4 text-center relative">
      {/* Prompts*/}
      {/* Reservation is 7 days out or less*/}
      <ConfirmationModal
        key="confirmationForReservationStartingWithDownloadWeek"
        isOpen={showReservationIsDueWithDownloadWeekModal}
        setIsOpen={setShowReservationIsDueWithDownloadWeekModal}
        question={`Your scheduled challenge starts soon! Would you like to kick things off with a Download Week to get ready?`}
        onConfirm={handleStart}
        showWarning={showWarning}
        warningText={`Switching now will cancel your current challenge.`}
        onRefute={handleDismissDownloadWeek}
      >
        <img className="w-full rounded-md object-cover transition-opacity duration-300 sm:group-hover:opacity-50 p-2" src={imageUrl} alt={displayedChallenge.title} />
      </ConfirmationModal>

      {/* Reservation is for today Prompt*/}
      <ConfirmationModal
        key="confirmationForReservationStartingToday"
        isOpen={showReservationIsDueModal}
        setIsOpen={setShowReservationIsDueModal}
        question={`Your scheduled challenge starts today! Would you like to start your new challenge?`}
        onConfirm={handleStart}
        showWarning={showWarning}
        warningText={`Switching now will cancel your current challenge.`}
        onRefute={handleDismissReservation}
      >
        <img className="w-full rounded-md object-cover transition-opacity duration-300 sm:group-hover:opacity-50 p-2" src={imageUrl} alt={displayedChallenge.title} />
      </ConfirmationModal>

      {/* User Clicked Cancel Reservation from the side bar */}
      <ConfirmationModal
        key="confirmationForCancellingReservation"
        isOpen={showCancelConfirmationModal}
        setIsOpen={setShowCancelConfirmationModal}
        question={`Are you sure you want to cancel this reservation?`}
        onConfirm={handleCancel}
      />

      {/* User Clicked Start Now Reservation */}
      <ConfirmationModal
        key="confirmationForStartingReservationWhenCurrentChallengeIsOngoing"
        isOpen={showStartConfirmationModal}
        setIsOpen={setShowStartConfirmationModal}
        question={`Are you sure you want to start this challenge? Your current challenge will be cancelled.`}
        onConfirm={handleStart}
      />

      <div className="relative group">
        <img className="w-full rounded-md object-cover " src={imageUrl} alt={displayedChallenge.title} />
      </div>

      <button
        className="absolute border border-teal bottom-2 right-2 px-2 text-center  rounded-full text-gray-500 hover:text-red-600 transition-colors"
        onClick={() => setShowCancelConfirmationModal(true)}
        disabled={cancelingReservation}
      >
        <span className="text-xs text-teal">cancel</span>
      </button>

      <button
        className="absolute border border-coral bottom-2 left-2 px-2 text-center  rounded-full text-gray-500 hover:text-red-600 transition-colors"
        onClick={() => setShowStartConfirmationModal(true)}
        disabled={cancelingReservation || startNowButtonDisabled}
      >
        <span className="text-xs text-coral">Start Now</span>
      </button>

      <h3 className="mt-3  text-lg font-semibold">{displayedChallenge.title}</h3>
      <p className="text-sm mb-4 text-gray-600">Starts on: {new Date(data.startDate).toLocaleDateString()}</p>
    </div>
  )
}

export { Reservation }
