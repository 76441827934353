export interface RequestError extends Error {
  status?: number
  message: string
}

//
// USER
//
export interface User {
  id: string
  email?: string
  firstName?: string
  lastName?: string
  profilePicture?: string
  pushToken?: string
  heightCM?: number
  weightKG?: number
  unitPreference?: 'imperial' | 'metric'
  birthday?: string | null
  gender?: GenderOption | null
  bmrRange?: BMR_Option
  chatToken?: string
  subscription?: UserSubscription
  preferences?: QuestionnaireAnswer[]
  streak: number
  highestStreak: number
  timezone?: string
}

export type BMR_Option = 'Low' | 'Medium' | 'High' | 'Very High'

export type GenderOption = 'Male' | 'Female' | 'Non-Binary' | '-' | null

export interface UserSubscription {
  type?: SubscriptionType
  stripe_customer_id?: string
  stripe_subscription_id?: string
  stripe_price_id?: string
  status?: SubscriptionStatus
  current_period_end?: Date
  cancelAtPeriodEnd?: boolean
  stripe_details?: StripeSubscriptionDetails
  created_at?: string
}

export interface StripeSubscriptionDetails {
  discount?: StripeDiscount
  coupon?: StripeCoupon
  latest_invoice?: any // TODO: Add proper invoice type if needed
}

export interface StripeDiscount {
  id: string
  object: 'discount'
  checkout_session: string | null
  coupon: StripeCoupon
  customer: string | null
  end: number | null
  invoice: string | null
  invoice_item: string | null
  promotion_code: string | null
  start: number
  subscription: string | null
}

export interface StripeCoupon {
  id: string
  object: 'coupon'
  amount_off: number | null
  created: number
  currency: string | null
  duration: 'forever' | 'once' | 'repeating'
  duration_in_months: number | null
  livemode: boolean
  max_redemptions: number | null
  metadata: Record<string, any>
  name: string | null
  percent_off: number | null
  redeem_by: number | null
  times_redeemed: number
  valid: boolean
}

export interface StripePromotionCode {
  id: string
  object: 'promotion_code'
  active: boolean
  code: string
  coupon: StripeCoupon
  created: number
  customer: string | null
  expires_at: number | null
  livemode: boolean
  max_redemptions: number | null
  metadata: Record<string, any>
  restrictions: {
    first_time_transaction: boolean
    minimum_amount: number | null
    minimum_amount_currency: string | null
  }
  times_redeemed: number
}

export type SubscriptionType = 'stripe' | 'ios'
export type SubscriptionStatus = 'active' | 'past_due' | 'unpaid' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'trialing'

export interface WorkoutFavorites {
  workout_id: string
}

export interface WorkoutFilterOptions {
  difficulty: Array<Difficulty>
  duration: Array<Duration>
  classType: Array<WorkoutClass>
}

//
// Auth State
// ----------------------------------------------------------------------

export type AuthState = {
  user: User | undefined
  isFetching: boolean
  error: Error | undefined
}

export type FetchingUserAction = {
  type: 'FETCHING_USER'
}

export type UpdatingUserAction = {
  type: 'UPDATING_USER'
}

export type FetchingUserSuccessAction = {
  type: 'FETCHING_USER_SUCCESS'
  user: User
  error: undefined
}

export type FetchingUserFailAction = {
  type: 'FETCHING_USER_FAIL'
  user: undefined
  error: Error
}

export type ResetUserStateAction = {
  type: 'RESET_STATE'
}

export type AuthActionTypes = FetchingUserAction | FetchingUserSuccessAction | FetchingUserFailAction | UpdatingUserAction | ResetUserStateAction

//
// Recipe Plan State
// ----------------------------------------------------------------------
export type RecipePlanState = {
  activeDate?: string
  activeRecipes: Record<string, Recipe>
  initialRecipes: Record<string, Recipe>
  activeMeal?: string
}

export type UpdateActiveDate = {
  type: 'UPDATE_ACTIVE_DATE'
  activeDate: string
}
export type UpdateActiveRecipes = {
  type: 'UPDATE_ACTIVE_RECIPES'
  activeRecipes: Record<string, Recipe>
}
export type SetRecipes = {
  type: 'SET_RECIPES'
  activeRecipes: Record<string, Recipe>
}
export type UpdateActiveMeal = {
  type: 'UPDATE_ACTIVE_MEAL'
  activeMeal?: string
}
export type ResetRecipePlan = {
  type: 'RESET_RECIPE_PLAN'
}

export type RecipePlanActionTypes = UpdateActiveDate | UpdateActiveRecipes | UpdateActiveMeal | SetRecipes | ResetRecipePlan

export interface Challenge {
  id: string
  title: string
  image: string
  releaseDate: string
  summary: string
  description: string
  includes: string[]
  equipment: boolean
  workouts?: Workout[]
  totalWorkouts: number
  type?: { title: string }
  recommendation?: string
  defaultCommitment?: number
  workoutsPerWeek?: number
  numberOfWeeks?: number
  categories?: ChallengeCategory[]
  orderNumber?: number
  hasMusic?: boolean
}

export type ChallengeMap = { [key: string]: Challenge }

//
// User Challenges
//

export interface ChallengeWorkout {
  id: string
  user_challenge_id: string
  scheduled_date: string
  workout_id: string
  challenge_id: string
  completed_date?: string
  challenge_start_date: string
  challenge_end_date: string
}

export interface ActiveChallenge {
  id: string
  challenge_id: string
  start_date: string
  end_date: string
  cancelled: boolean
  challenge: Challenge
  total_completed_workouts?: number
  recommendation?: string
  weekly_commitment?: number
  defaultCommitment?: number
  numberOfWeeks?: number
  with_music?: boolean
}

export type UserChallengeState = {
  completedChallenges: { [key: string]: number } | null | undefined
  completedChallengesLoading: boolean
  activeChallenge: ActiveChallenge | null | undefined
  isFetching: boolean
  error: Error | undefined
}

export type ChallengeCategory = {
  id: string
  displayName: string
  orderNumber: number
  description: string
  icon?: string
}

export type Series = {
  id: string
  displayName: string
  description: string
  image: string
  challenges: Challenge[]
  orderNumber: number
}

export type ChallengeState = {
  challenges: ChallengeMap | null
  categories: ChallengeCategory[] | null
  // challengesByCategory: { [key: string]: Challenge[] } | null
  ready: boolean
  array: Challenge[]
  isFetching: boolean
  error: Error[] | undefined
  series: Series[]
  challengesAndSeriesCombined: (Series | Challenge)[]
}

//
// UI
// ----------------------------------------------------------------------

// Local Storage
export type StorageObj = {
  [key: string]: string
}

export interface Option {
  title: string
  description: string
  icon: React.FC<React.SVGProps<SVGSVGElement>> | null
}

export interface FormValidationError {
  email?: string
  password?: string
  general?: string
  firstName?: string
  lastName?: string
  confirmPassword?: string
}

//
// WORKOUTS
// ----------------------------------------------------------------------

export interface FiltersQuery {
  duration?: number[]
  difficulty?: string
  type?: string
  classType?: string
  favorites?: 'true' | 'false'
}

export interface Workout {
  id: string
  title: string
  summary: string
  previewImageUrl: string
  duration: Duration
  type: WorkoutType
  finisher?: Finisher | null
  difficulty: Difficulty
  signedVideoUrl?: string
  signedVideoWithMusicUrl?: string
  classType?: WorkoutClass
  equipment?: boolean
  score?: number
  completed_date?: string
  challenge_workout?: ChallengeWorkout
}

export interface WorkoutType {
  id: string
  title: string
  description: string
  slug: string
  icon: string | React.FC<React.SVGProps<SVGSVGElement>>
  image: string | React.FC<React.SVGProps<SVGSVGElement>>
}

export interface WorkoutClass {
  id: string
  title: string
  image: string
  icon?: string
  filterByKey: string
}

export interface Finisher {
  id: string
  title: string
  subtitle: string
  workout: Workout | null
  challenge_workout?: ChallengeWorkout
  previewImageUrl: string
  description: string
  duration: Duration
}

export interface CompletedWorkout {
  id: string
  user_id: string
  workout_id: string
  completed_date: Date
  challenge_workout_id?: string
  score?: number
}
export interface CompletedWorkoutPrisma {
  id: string
  userId: string
  workoutId: string
  completedDate: Date
  challengeWorkoutId?: string
  score?: number
}

export type WorkoutsByDate = {
  [date: string]: Workout[]
}

//
// RECIPES
//

export interface Recipe {
  id: string
  title: string
  description: string
  photos: Array<string>
  duration: Duration
  category: RecipeCategory
  instructions: string
  ingredients: string
  cookTime: number
  prepTime: number
  servingSize?: number
  variants: RecipeVariant[]
  scheduled_recipe?: ScheduledRecipe
}

export type RecipeVariantBucket = 'Low' | 'Medium' | 'High' | 'Very High'
export interface RecipeVariant {
  title: string
  variant: RecipeVariantBucket
  instructions: string
  ingredients: string
  servingSize: number
}

export type ScheduledRecipe = {
  id: string
  recipe_id: string
  scheduled_date: Date
  meal_type: string
}
export interface RecipeCategory {
  id: string
  title: string
  description?: string
}

export interface RecipesFiltersQuery {
  duration?: number
  type?: string
  tags?: string
}

export type RecommendedRecipes = {
  [key: string]: Recipe
}

export type MealType = {
  id: string
  title: string
  icon: string
  order: number
}
//
// QUESTIONNAIRE
//

export type Questionnaire = {
  id: string
  title: string
  questions: Question[]
}

export type Question = {
  id: string
  question: string
  description: string
  options: QuestionOption[]
  preference_type?: string
}

export type QuestionOption = {
  id: string
  title: string
  description: string
  icon?: string
}

export type QuestionnaireAnswer = {
  id: string
  user_id: string
  questionnaire_title: string
  question_id: string
  option_id: string
  record: QA_Record
  preference_type?: string
}

export type QA_Record = {
  question: string
  answer: string
}

export type QuestionPreference = {
  id: string
  record: QA_Record
  preference_type: string
  question_id: string
  option_id: string
}

//
// Transformation Photos
//

export type TransformationPhoto = {
  id: string
  user_id: string
  photo_url: string
  created_at: Date
}

export type TransformationPhotosByDate = {
  [date: string]: TransformationPhoto[]
}

export type LightboxPhoto = {
  src: string
  width: number | string
  height: number | string
}

//
// GENERAL
//
export interface Duration {
  id: string
  title: string
  length: number
  filterByKey: 'length'
}
export interface Difficulty {
  id: string
  title: string
  filterByKey: 'title'
}

//
// SUBSCRIPTIONS
//

export type SubscriptionOptionType = {
  id: string
  type: 'monthly' | 'yearly' | 'quarterly'
  period: string
  currency: string
  hasPromo?: boolean
  promoPrice?: number
  promoText?: string
  price: Price
  total: number
  basePrice: number
}

export interface Price {
  id: string
  active: boolean
  currency: string
  metadata: Record<string, string>
  product: {
    id: string
    default_price: string
  }
  recurring: {
    interval: 'day' | 'month' | 'week' | 'year'
    interval_count: number
    trial_period_days?: number | null
    usage_type?: 'licensed' | 'metered'
    aggregate_usage?: 'last_during_period' | 'last_ever' | 'max' | 'sum' | null
  } | null
  unit_amount: number
  unit_amount_decimal: string | null
  billing_scheme?: 'per_unit' | 'tiered'
  created?: number
  livemode?: boolean
  lookup_key?: string | null
  nickname?: string | null
  tax_behavior?: 'inclusive' | 'exclusive' | 'unspecified' | null
  type?: 'one_time' | 'recurring'
}

//
// RESOURCES
//

export interface Resource {
  id: string
  title: string
  description: string
  sections: ResourceSection[]
}

export interface ResourceSection {
  id: string
  title: string
  body: string
}

export type ResourceState = {
  currentResource: Resource | null | undefined
  isFetching: boolean
  error: Error | undefined
}

export enum PromotionType {
  BOXINGWEEK = 'boxingWeek',
  NEWYEAR = 'newYear',
}

export enum AchievementType {
  EVENT = 'EVENT',
  DYNAMIC = 'DYNAMIC',
  REGULAR = 'REGULAR',
}

export type AchievementCriteria = {
  id?: string
  achievementId: string
  target: number
  displayText: string
  promptText: string
  congratsText: string
  derived: boolean
  ruleId?: string
}

export interface Achievement {
  id?: string
  name: string
  summaryMd: string
  descriptionMd: string
  imageUrl: string
  type: AchievementType | string
  startDate: string
  endDate: string
  durationDays: number
  requiresOptIn: boolean
  completedCount: number
  badgeImageUrl: string
  active: boolean
  available: boolean
  publishDate: string
  oneStarMinPercent: number
  twoStarMinPercent: number
  threeStarMinPercent: number
  starCongratsText: string
  ruleSetId: string
  achievementCriteria?: AchievementCriteria[]
}

export interface UserAchievement {
  [x: string]: string | undefined | boolean | number | Achievement | UserAchievementCriteria[]
  id?: string
  userId: string
  achievementId: string
  userChallengeId?: string
  status: string
  starRating?: number
  completed: boolean
  completedDate?: string
  startDate?: string
  endDate?: string
}

export interface UserAchievementCriteria {
  id?: string
  userAchievementId: string
  achievementCriteriaId: string
  value: number
  completed: boolean
  completedDate?: string
  achievementCriteria?: AchievementCriteria
}

export interface UserAchievementWithRelatedData extends UserAchievement {
  achievement: Achievement
  userAchievementCriteria: UserAchievementCriteria[]
}

export interface UserAchievementCriteriaWithRelatedData extends UserAchievementCriteria {
  achievementCriteria: AchievementCriteria
  userAchievement: UserAchievement & {
    achievement: Achievement
  }
}

export interface ValidationErrors {
  [key: string]: string
}

export interface AchievementFormState extends Partial<Achievement> {
  name: string
  summaryMd: string
  descriptionMd: string
  imageUrl: string
  type: AchievementType
  startDate: string
  endDate: string
  durationDays: number
  requiresOptIn: boolean
  badgeImageUrl: string
  active: boolean
  available: boolean
  oneStarMinPercent: number
  twoStarMinPercent: number
  threeStarMinPercent: number
  publishDate: string
  starCongratsText: string
  ruleSetId: string
  validationErrors: ValidationErrors
  errorMsg: string | null
}
export interface ReportableUacsResponse {
  today: UserAchievementCriteriaWithRelatedData[]
  yesterday: UserAchievementCriteriaWithRelatedData[]
}

export type Coupon = {
  id: string
  amount_off: number
  currency: string
  duration: string
  livemode: boolean
  max_redemptions: number
  metadata: any
  name: string
  percent_off: number
  redeem_by: string
  valid: boolean
  applies_to: { products: string[] }
}

export type PromoCode = {
  id: string
  code: string
  coupon: Coupon
  created: string
  object: string
  metadata: any
  expires_at: string
}

export type Product = {
  id: string
  object: string
  active: boolean
  default_price: string
  description: string
  livemode: boolean
  metadata: any
  name: string
}

export interface Discount {
  targetPlan: string
  targetProduct: string
  discountAmount: number
  discountPercentage: number
  newPrice: number
  oldPrice: number
}

// Define the type for the state and the update function of doneReportingForYesterday
export type DoneReportingStateTuple = [boolean, React.Dispatch<React.SetStateAction<boolean>>]

export type CurrentViewOptions = 'paymentForm' | 'updatePlan' | 'updatePayment' | 'cancelSubscription' | 'applyCouponToActiveSubscription' | null

export type ModalCurrentViewOptions = 'cancel' | 'update' | null

export type ChallengeAchievementAssociation = { challengeId: string; achievementId: string }

export type ReservationPreferences = {
  includeDownloadWeek: boolean
  weeklyCommitment: number
  withMusic: boolean
}

// Types
export type Reservation = {
  id: string
  userId: string
  challengeId: string
  startDate: string
  preferences: ReservationPreferences
  status: string
  createdAt: string
  updatedAt: string
}
